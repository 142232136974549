// single ===================================================*/
article.single{
	@media #{$sp}{
		margin-bottom: vsp(40);
	}
	section.post{
		margin-bottom: 69px;
		padding: 0 0 0 0;
		.wrap{
			.content{
				& > .header{
					display: table;
					width: 100%;
					margin-bottom: 30px;
					table-layout: fixed;
					@media #{$sp}{
						@include flex;
						flex-direction: column-reverse;
					}
					.gallery{
						@extend %clearfix;
						display: table-cell;
						vertical-align: top;
						@media #{$sp}{
							@include flex;
							flex-direction: column;
							width: 100%;
						}
						.gallery__body{
							@extend %clearfix;
							overflow: hidden;
							width: 400px;
							height: 400px;
							@media #{$sp}{
								width: 100%;
							}
						}
						.gallery__thumb{
							@extend %clearfix;
							width: 400px;
							margin-top: 5px;
							@media #{$sp}{
								@include flex;
								justify-content: flex-start;
								width: 100%;
							}
							a{
								float: left;
								width: 19%;
								margin-right: 1.25%;
								&:last-child{
									margin-right: 0;
								}
								@media #{$sp}{
									float: none;
									width: 19%;
									margin-right: 1.25%;
								}
							}
						}
					}
					.meta{
						display: table-cell;
						width: 330px;
						padding-left: 20px;
						vertical-align: middle;
						@media #{$sp}{
							@include flex;
							align-items: flex-start;
							flex-direction: column;
							width: 100%;
							margin-bottom: vsp(20);
							padding-left: 0;
						}
						.case{
							@include font-maven;
							@include font-size(16);
							display: inline-block;
							margin-bottom: 16px;
							padding: 6px 24px;
							text-transform: uppercase;
							color: #fff;
							background-color: #333;
						}
						.category{
							@include font-size(12);
							display: inline-block;
							padding: 7px 20px;
							border-radius: 30px;
							background-color: #f3f2ef;
						}
					}
				}
				& > .post_content{
					// .wpcf7{
					// 	display: none;
					// }
				}
				& > .post_content,
				& .form_content{
					@extend %post_content;
				}
				div[id^="wpcf7-f883"],
				#wpcf7-f883-p953-o1{
					display: none;
				}
				& .form_content{
					@media #{$sp}{
						padding-top: vsp(40);
					}
					table{
						@extend %table_style;
						float: none;
						width: 100%;
						tbody{
							tr{
								@media #{$sp}{
									display: block;
									width: 100%;
								}
								th,
								td{
									@include font-size(16);
								}
								th{
									width: 40%;
									@media #{$sp}{
										display: block;
										width: 100%;
										padding-bottom: 8px;
									}
								}
								td{
									width: 60%;
									@media #{$sp}{
										display: block;
										width: 100%;
										padding-top: 8px;
									}
								}
							}
						}
					}
				}
			}
		}
		section.orderflow{
			&.orderflow-pc{
				display: block;
				@media #{$sp}{
					display: none;
				}
			}
			&.orderflow-sp{
				display: none;
				@media #{$sp}{
					display: block;
				}
			}
			.wrap{
				.content{
					.orderflow-contact-c{
						display: none;
					}
					.orderflow-c{
						width: 730px;
						max-width: 100%;
					}
					.orderflow-contact-logo{
						width: 143px;
						margin: 0;
						padding: 0 20px 0 20px;
						background-size: cover;
						&:after{
							margin-left: -130px;
						}
						img{
							width: 100px;
						}
					}
					.orderflow-contact-list{
						p{
							&.tel{
								a{
									@include font-size(18);
									@include letter-spacing(18, 1);
								}
							}
							&.form{
								a{
									@include font-size(15);
									@include letter-spacing(15, .5);
								}
							}
						}
					}
				}
			}
		}
	}
	section.recent{
		border-radius: 3px;
		background: transparent url('#{$imgPath}/bg/bg_recentpost.png') center center no-repeat;
		background-size: cover;
		@media #{$sp}{
			padding: vsp(30) vsp(16);
			background: transparent url('#{$imgPath}/bg/bg_recentpost@2x.jpg') center center no-repeat;
			background-size: cover;
		}
		& > .wrap{
			width: 100%;
			text-align: center;
			& > .content{
				width: 100%;
				padding: 0 47px 0;
				@media #{$sp}{
					@include flex;
					flex-direction: column;
					padding: 0;
				}
				& > article{
					float: left;
					width: 180px;
					margin: 0 45px 0 0;
					&:last-child{
						margin: 0 0 0 0;
					}
					@media #{$sp}{
						@include flex;
						float: none;
						width: 100%;
						margin: 0 0 vsp(12) 0;
						padding: 0 0 vsp(12) 0;
						border-bottom: 1px solid rgba($brand_brown, .15);
						&:last-child{
							margin: 0;
							padding: 0;
							border-bottom: none;
						}
					}
					.thumb{
						margin-bottom: 10px;
						@media #{$sp}{
							width: 30%;
							margin-bottom: 0;
						}
						a{
							img{
								opacity: 1;
							}
							&:hover{
								img{
									opacity: .7;
								}
							}
						}
					}
					.body{
						@media #{$sp}{
							width: 70%;
							padding-left: vsp(12);
						}
					}
					time{
						@include font-maven;
						@include font-size(12);
						font-weight: bold;
						display: block;
						margin-bottom: 6px;
						text-align: left;
						letter-spacing: 1px;
						color: #333;
					}
					p{
						text-align: left;
						a{
							@include font-size(13);
							line-height: 1.4;
							text-decoration: none;
							color: #333;
							&:hover{
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
}
