// company ===================================================*/
article.page.company{
	section.company{
		& > .wrap{
			& > .content{
				.logo{
					margin-top: 72px;
					margin-bottom: 72px;
					img{
						display: block;
						margin: 0 auto;
					}
				}
				p.lead{
					@include font-size(16);
					line-height: 1.88;
					margin-bottom: 40px;
					text-align: center;
					letter-spacing: 4px;
					@media #{$sp}{
						@include font-size(14);
						margin-bottom: vsp(30);
						text-align: left;
						letter-spacing: .1em;
						br{
							display: none;
						}
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	}
	section.outline{
		& > .wrap{
			& > .content{
				& + .h2{
					margin-top: 36px;
				}
				.lead{
					@extend %clearfix;
					width: 100%;
					p{
						@include font-size(16);
						line-height: 1.88;
						margin-bottom: 40px;
						text-align: center;
						letter-spacing: 4px;
						&:last-child{
							margin-bottom: 0;
						}
						@media #{$sp}{
							@include font-size(14);
							margin-bottom: vsp(30);
							text-align: left;
							letter-spacing: .1em;
							br{
								display: none;
							}
						}
					}
				}
				.gallery{
					ul{
						@extend %clearfix;
						width: 100%;
						margin: 36px 0;
						@media #{$sp}{
							@include flex;
							flex-direction: column;
						}
						li{
							float: left;
							width: 32%;
							margin-right: 2%;
							&:last-child{
								margin-right: 0;
							}
							@media #{$sp}{
								width: 100%;
								margin-right: 0;
								margin-bottom: vsp(20);
								&:last-child{
									margin-bottom: 0;
								}
								img{
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
				table{
					@extend %table_style;
				}
			}
		}
	}
}
