// footer ===================================================*/	& > footer{
footer{
	width: 100%;
	& > .wrap{
		width: 100%;
		& > .content{
			width: 1000px;
			margin: 0 auto;
			@media #{$sp}{
				width: 100%;
			}
		}
	}
	address{
		width: 100%;
		small{
			display: block;
			width: 1000px;
			margin: 0 auto;
			@media #{$sp}{
				width: 100%;
			}
		}
	}
}

footer{
	background-color: #8a847a;
	& > .wrap{
		& > .content{
			padding: 35px 0;
			nav.sitemap{
				text-align: center;
				vertical-align: top;
				& > ul{
					// display: inline-block;
					@include flex;
					align-items: flex-start;
					justify-content: center;
					vertical-align: top;
					@media #{$sp}{
						flex-wrap: wrap;
						justify-content: flex-start;
						width: 100%;
						padding-right: vsp(16);
						padding-left: vsp(16);
					}
					& > li{
						margin-right: 20px;
						@media #{$sp}{
							width: 48%;
							margin-right: 0;
							margin-bottom: vsp(12);
							text-align: left;
							&:last-child{
								margin-right: 0;
							}
						}
						&:last-child{
							margin-right: 0;
						}
						& > a{
							@include font-size(12);
							padding-left: 16px;
							text-decoration: none;
							color: #fff;
							background: transparent url('#{$imgPath}/footer/icon_sitemap.png') left center no-repeat;
							@media #{$sp}{
								@include font-size-sp(10);
								padding: .2em 16px;
								background: transparent url('#{$imgPath}/footer/icon_sitemap.svg') left center no-repeat;
							}
							&:hover{
								text-decoration: underline;
							}
						}
						& > ul{
							margin-top: 10px;
							margin-left: 30px;
							text-align: left;
							& > li{
								margin-bottom: 8px;
								text-align: left;
								&:last-child{
									margin-bottom: 0;
								}
								& > a{
									@include font-size(11);
									padding-left: 8px;
									text-decoration: none;
									color: #fff;
									background: transparent url('#{$imgPath}/footer/icon_sitemap_s.png') left center no-repeat;
									&:hover{
										text-decoration: underline;
									}
								}
							}
						}
					}
				}
			}
			.shopinfo{
				padding: 46px 0 0 0;
				text-align: center;
				@media #{$sp}{
					padding-top: 35px;
				}
				img{
					display: block;
					margin: 0 auto;
				}
				p{
					@include font-size(10);
					line-height: 1.4;
					display: inline-block;
					margin-top: 16px;
					padding: 16px 28px 0;
					color: #fff;
					border-top: 1px solid #9d9890;
					.address{
						br{
							display: none;
						}
					}
				}
			}
		}
	}
	address{
		background-color: #605853;
		small{
			@include font-size(10);
			@include font-maven-bold;
			font-weight: bold;
			font-style: normal;
			padding: 12px 0;
			text-align: center;
			letter-spacing: 1.5px;
			text-transform: uppercase;
			color: #fff;
			@media #{$sp}{
				@include font-size-sp(10);
				letter-spacing: .1em;
			}
		}
	}
}
