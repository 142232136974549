// navigation ===================================================*/
nav.global{
	width: 100%;
	& > ul{
		display: table;
		width: 100%;
		table-layout: fixed;
		& > li{
			display: table-cell;
			text-align: center;
			vertical-align: top;
			border-left: 1px solid #ececec;
			&:last-child{
				border-right: 1px solid #ececec;
			}
			a{
				display: block;
				height: 100%;
				padding: 28px 0 13px 0;
				text-decoration: none;
				span.icon{
					display: block;
					width: auto;
					height: auto;
					min-height: 18px;
					margin-bottom: 14px;
				}
				span.title{
					@include font-hannari;
					@include font-size(12);
					display: block;
					margin: 0 10px;
					padding-bottom: 13px;
					transition: 300ms;
					letter-spacing: normal;
					letter-spacing: -.5px;
					color: #444;
					border-bottom: 5px solid #fff;
					span{
						@include font-merriweather;
						@include font-size(8);
						font-weight: bold;
						display: block;
						margin-top: 10px;
						letter-spacing: 1.5px;
						color: $brand_brown;
					}
				}
				&:hover{
					span.title{
						transition: 300ms;
						border-bottom: 5px solid #eee;
					}
				}
			}
			&.global_1{
				width: 105px + 38px;
				padding: 0 12px;
				vertical-align: middle;
				a{
					transition: 300ms;
					opacity: 1;
					&:hover{
						transition: 300ms;
						opacity: .6;
					}
				}
			}
			// &.global_2{
			// 	span.icon{
			// 		margin-bottom: 6px;
			// 	}
			// 	span.title{
			// 		span{
			// 			margin-top: 6px;
			// 		}
			// 	}
			// }
			&.global_7{
				width: 230px;
				padding-top: 0;
				& > ul{
					& > li{
						display: block;
						padding: 16px 12px;
						border-bottom: 1px solid #ececec;
						&:last-child{
							border-bottom: none;
						}
						&.global_7_top{
							padding: 10px 12px;
							a.tel{
								@include font-maven;
								@include font-size(24);
								display: block;
								margin-bottom: 4px;
								padding: 0;
								padding-left: 16px;
								text-decoration: none;
								letter-spacing: 1px;
								color: #333;
								background: transparent url('#{$imgPath}/header/icon_tel.png') 4px center no-repeat;
							}
							p.announce{
								@include font-hannari;
								@include font-size(11);
								padding: 3px 0;
								letter-spacing: -.5px;
								color: #444;
								background-color: $brand_white;
							}
						}
						&.global_7_bottom{
							@include flex;
							align-items: center;
							justify-content: flex-start;
							padding: 0;
							.social-lists{
								padding: 14px 12px;
								text-align: left;
							}
							.social-lists_item{
								display: inline-block;
								margin-right: 8px;
								&:last-child{
									margin-right: 0;
								}
							}
							.social-lists_item a{
								height: auto;
								padding: 0;
								img{
									border-radius: 2px;
								}
							}
							// a{
							// 	line-height: 1.2;
							// 	display: block;
							// 	width: 100%;
							// 	padding: 4px 30px 4px 32px;
							// 	text-align: left;
							// 	letter-spacing: normal;
							// 	color: #333333;
							// 	background-image: url('#{$imgPath}/header/icon_facebook.png'), url('#{$imgPath}/bg/bg_header_arrow.png');
							// 	background-repeat: no-repeat, no-repeat;
							// 	background-position: left center, right 10px center;
							// 	@include font-size(10);
							// 	@include font-hannari;
							// 	span{
							// 		@include font-size(11);
							// 	}
							// 	&:hover{
							// 		background-position: left center, right 0 center;
							// 	}
							// }
						}
					}
				}
			}
		}
	}
}

header.animation{
	& > .pc.wrap{
		& > .content{
			nav.global{
				& > ul{
					& > li{
						text-align: center;
						vertical-align: middle;
						&:last-child{
						}
						a{
							padding: 12px 8px;
							span.icon{
								display: block;
								width: 0;
								height: 0;
								min-height: 0;
								margin-right: 0;
								margin-bottom: 0;
							}
							span.title{
								@include font-hannari;
								@include font-size(12);
								display: inline-block;
								margin: 0;
								padding-top: 5px;
								padding-bottom: 5px;
								transition: 200ms;
								letter-spacing: -.5px;
								color: #444;
								border-bottom: 3px solid #fff;
								span{
									font-size: 0;
									width: 0;
									height: 0;
									margin-top: 0;
									letter-spacing: 0;
								}
							}
							&:hover{
								span.title{
									transition: 300ms;
									border-bottom: 3px solid #eee;
								}
							}
						}
						&.global_1{
							width: 105px + 38px;
							padding: 0 12px;
							vertical-align: middle;
							a{
								transition: 300ms;
								opacity: 1;
								&:hover{
									transition: 300ms;
									opacity: .6;
								}
							}
						}
						&.global_7{
							width: auto;
							padding-top: 0;
							& > ul{
								& > li{
									display: block;
									padding: 12px;
									border-bottom: none;
									&:last-child{
										border-bottom: none;
									}
									&.global_7_top{
										font-size: 0;
										overflow: hidden;
										width: 0;
										height: 0;
										padding: 0;
										a.tel{
											font-size: 0;
											display: none;
											margin-bottom: 0;
											padding: 0;
											padding-left: 0;
											text-align: left;
											letter-spacing: 0;
											background: transparent url('#{$imgPath}/header/icon_tel.png') 4px center no-repeat;
										}
										p.announce{
											@include font-hannari;
											@include font-size(11);
											font-size: 0;
											padding: 0;
											letter-spacing: 0;
											color: #fff;
											background-color: $brand_white;
										}
									}
									&.global_7_bottom{
										@include flex;
										align-items: center;
										justify-content: center;
										padding: 0;
										.social-lists{
											padding: 8px 12px;
											text-align: left;
										}
										.social-lists_item{
											display: inline-block;
											margin-right: 8px;
											&:last-child{
												margin-right: 0;
											}
										}
										.social-lists_item a{
											height: auto;
											padding: 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


header,
header.animation *{
	transition: 200ms;
}

nav.snav{
	background-color: #fff;
	.wrap{
		.content{
		}
	}
	ul.menu{
		li{
			border-bottom: 1px solid #ececec;
			a{
				@include font-size(14);
				@include font-hannari;
				display: block;
				padding: 16px vsp(16);
				text-decoration: none;
				color: #444;
				.eng{
					@include font-merriweather;
					@include font-size(9);
					font-weight: bold;
					display: inline-block;
					padding-left: .5em;
					letter-spacing: .1em;
					text-transform: uppercase;
					color: $brand_brown;
				}
			}
		}
	}
	.info{
		width: 100%;
		padding: 16px vsp(16);
		border-bottom: 1px solid #ececec;
		p.tel{
			@include font-maven;
			@include font-size(22);
			margin-bottom: 8px;
			text-align: center;
			letter-spacing: 1px;
			span{
				padding-left: 1em;
				background: transparent url('#{$imgPath}/header/icon_tel.svg') top .25em left 0 no-repeat;
				background-size: auto .7em;
			}
			a{
				@include font-maven;
				@include font-size(22);
				text-decoration: none;
				letter-spacing: 1px;
				color: #444;
			}
		}
		p.announce{
			@include font-hannari;
			@include font-size(11);
			width: 100%;
			padding: vsp(4) 0;
			text-align: center;
			color: #444;
			background-color: $brand_white;
		}
	}
	.sns{
		width: 100%;
		padding: 16px vsp(16);
		.social-lists{
			@include flex;
			align-items: flex-start;
			justify-content: center;
			width: 100%;
		}
		.social-lists_item{
			margin-right: 16px;
			&:last-child{
				margin-right: 0;
			}
			img{
				width: vsp(32);
				height: auto;
				border-radius: 2px;
			}
		}
	}
}
