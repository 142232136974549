// catch ===================================================*/
section.catch{
	height: 600px;
	// padding: 100px 0 87px 0;
	padding: 100px 0 100px 0;
	background-color: transparent;
	// background-color: transparent;
	// background-image: url('#{$imgPath}/bg/bg_catch_cover.png'), url('#{$imgPath}/bg/bg_catch.jpg');
	// background-repeat: no-repeat, no-repeat;
	// background-attachment: scroll, fixed;
	// background-position: center bottom, center center;
	// background-size: contain, cover;
	&:before{
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		display: block;
		overflow: hidden;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 100vw;
		max-width: 100vw;
		height: 600px;
		content: '';
		background-image: url('#{$imgPath}/bg/bg_catch_cover.png');
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: center bottom -1px;
		background-size: contain;
	}
	@media #{$sp}{
		height: auto;
		// background-image: url('#{$imgPath}/bg/bg_catch_cover.png'), url('#{$imgPath}/bg/bg_catch@2x.jpg');
	}
	& > .wrap{
		margin: auto 0;
		@media #{$sp}{
			width: 100%;
			min-width: initial;
			min-width: auto;
		}
		& > .content{
			@media #{$sp}{
				width: 100%;
			}
			ul{
				text-align: center;
				@media #{$sp}{
					@include flex;
					align-items: center;
				}
				li{
					display: inline-block;
					width: 200px;
					height: 200px;
					border: 1px solid #fff;
					border-radius: 50%;
					background-color: rgba(0,0,0,.05);
					@media #{$sp}{
						width: 34vw;
						height: 34vw;
					}
					span.icon{
						display: block;
						margin-bottom: 10px;
					}
					p.title{
						@include font-maven;
						@include font-size(18);
						margin-bottom: 6px;
						text-transform: uppercase;
						color: #fff;
						@media #{$sp}{
							// @include font-size-sp(14);
							@include font-size-sp(12);
							letter-spacing: normal;
						}
					}
					p{
						@include font-hannari;
						@include font-size(12);
						line-height: 1.33;
						color: #fff;
						@media #{$sp}{
							@include font-size-sp(8);
						}
					}
					&.point_1{
						padding-top: 41px;
						transform: translateX(15px);
						@media #{$sp}{
							padding-top: 3vw;
							transform: translateX(0);
						}
					}
					&.point_2{
						padding-top: 41px;
						@media #{$sp}{
							margin-left: vsp(-20);
							padding-top: 3vw;
							transform: translateX(0);
						}
					}
					&.point_3{
						padding-top: 41px;
						transform: translateX(-15px);
						@media #{$sp}{
							margin-left: vsp(-20);
							padding-top: 3vw;
							transform: translateX(0);
						}
					}
				}
			}
		}
	}
}

// section.catchのiPad用の背景画像の固定
html{
	&.ipad{
		&.home{
			&:after{
				background-size: auto 130%;
			}
		}
	}
	&.home{
		position: relative;
		&:after{
			position: fixed;
			z-index: -2;
			top: 149px;
			left: 0;
			display: block;
			overflow: hidden;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			width: 100vw;
			max-width: 100vw;
			height: 600px;
			content: '';
			background-image: url('#{$imgPath}/bg/bg_catch.jpg');
			background-repeat: no-repeat;
			background-attachment: scroll;
			background-position: center bottom -1px;
			background-size: cover;
			@media #{$sp}{
				display: none;
			}
		}
		// &:after{
		// 	position: fixed;
		// 	z-index: -2;
		// 	left: 0;
		// 	display: block;
		// 	-webkit-box-sizing: border-box;
		// 	box-sizing: border-box;
		// 	width: 100vw;
		// 	height: 600px;
		// 	content: '';
		// 	background-image: url('../../images/bg/bg_catch.jpg');
		// 	background-repeat: no-repeat;
		// 	background-position: top center;
		// 	background-size: 130% auto;
		// 	@media #{$sp}{
		// 		z-index: -1;
		// 		background-image: url('#{$imgPath}/bg/bg_catch@2x.jpg');
		// 		background-size: cover;
		// 	}
		// }
		section.catch{
			position: relative;
			z-index: 2;
			overflow: hidden;
			width: 100%;
			// background-color: transparent;
			// background-image: none;
			& > .wrap{
				position: relative;
				z-index: 3;
			}
		}
	}
}

// catch ===================================================*/
section.news{
	@include section-padding;
	background-color: #fff;
	& > .wrap{
		& > .content{
			article{
				@extend %clearfix;
				display: table;
				margin-bottom: 12px;
				table-layout: fixed;
				&:last-child{
					margin-bottom: 0;
				}
				@media #{$sp}{
					@include flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: flex-start;
				}
				p.category{
					@include font-hannari;
					display: table-cell;
					width: 120px;
					padding: 6px 0;
					text-align: center;
					vertical-align: middle;
					border-radius: 2px;
					background-color: $brand_white;
					@media #{$sp}{
						@include flex;
						justify-content: center;
						width: auto;
						padding: vsp(6) vsp(16);
					}
					span{
						@include font-size(12);
						padding-left: 18px;
						letter-spacing: normal;
						background-repeat: no-repeat;
						background-position: left center;
						&.topic{
							background-image: url('#{$imgPath}/top/icon_category_topic.png');
							@media #{$sp}{
								background-image: url('#{$imgPath}/top/icon_category_topic.svg');
							}
						}
						&.reform{
							background-image: url('#{$imgPath}/top/icon_category_reform.png');
							@media #{$sp}{
								background-image: url('#{$imgPath}/top/icon_category_reform.svg');
							}
						}
						&.furniture{
							background-image: url('#{$imgPath}/top/icon_category_furniture.png');
							@media #{$sp}{
								background-image: url('#{$imgPath}/top/icon_category_furniture.svg');
							}
						}
					}
				}
				time{
					@include font-maven;
					@include font-size(13);
					display: table-cell;
					margin: 0 0 0 4px;
					padding-right: 14px;
					padding-left: 14px;
					vertical-align: middle;
					background: transparent url('#{$imgPath}/top/icon_news_arrow.png') right 4px center no-repeat;
					@media #{$sp}{
						display: flex;
					}
				}
				h1{
					@include font-size(13);
					display: table-cell;
					padding-left: 8px;
					vertical-align: middle;
					color: $brand_black;
					@media #{$sp}{
						line-height: 1.6;
						display: flex;
						width: 100%;
						margin-top: 10px;
						padding-left: 0;
					}
					a{
						display: inline-block;
						padding-bottom: 2px;
						transition: .3s;
						text-decoration: none;
						color: $brand_red;
						border-bottom: 1px dotted #fff;
						&:hover{
							transition: .3s;
							text-decoration: none;
							border-bottom: 1px dotted $brand_red;
						}
					}
				}
			}
		}
	}
}

// catch ===================================================*/
section.proposal{
	@include section-padding;
	background: transparent url('#{$imgPath}/bg/bg_proposal.jpg') center center no-repeat;
	background-size: cover;
	@media #{$sp}{
		background: transparent url('#{$imgPath}/bg/bg_proposal@2x.jpg') center bottom no-repeat;
		background-size: cover;
	}
	& > .wrap{
		& > .content{
			ul{
				width: 906px;
				@media #{$sp}{
					@include flex;
					align-items: flex-start;
					flex-wrap: wrap;
					width: 100%;
				}
				li{
					float: left;
					width: 212px;
					margin-right: 19px;
					@media #{$sp}{
						width: 48%;
						margin-right: 0;
						margin-bottom: vsp(16);
					}
					&:last-child{
						margin-right: 0;
					}
					.thumb{
						margin-bottom: 13px;
						border: 4px solid #fff;
						img{
							width: 210px;
							height: 157px;
							@media #{$sp}{
								width: 100%;
								max-width: 100%;
								height: auto;
							}
						}
					}
					p{
						@include font-size(13);
						line-height: 1.38;
						padding: 0 8px;
						text-align: center;
						// letter-spacing: 2px;
						letter-spacing: 1px;
						color: #4c483c;
						@media #{$sp}{
							padding: 0;
						}
					}
				}
			}
		}
	}
}

// catch ===================================================*/
// section.orderflow{
// 	@include section-padding;
// 	@media #{$sp}{
// 		display: none;
// 	}
// 	& > .wrap{
// 		& > .content{
// 			ul{
// 				width: auto;
// 				margin: 0 auto;
// 				text-align: center;
// 				li{
// 					display: inline-block;
// 					min-height: 40px;
// 					margin-right: 30px;
// 					padding-right: 40px;
// 					padding-left: 55px;
// 					text-align: left;
// 					background-repeat: no-repeat, no-repeat;
// 					&:last-child{
// 						margin-right: 0;
// 						padding-right: 0;
// 					}
// 					&:nth-of-type(1){
// 						background-image: url('#{$imgPath}/bg/bg_orderflow_1.png'), url('#{$imgPath}/bg/bg_orderflow_arrow.png');
// 						background-position: 0 center, right center;
// 					}
// 					&:nth-of-type(2){
// 						background-image: url('#{$imgPath}/bg/bg_orderflow_2.png'), url('#{$imgPath}/bg/bg_orderflow_arrow.png');
// 						background-position: 0 center, right center;
// 					}
// 					&:nth-of-type(3){
// 						background-image: url('#{$imgPath}/bg/bg_orderflow_3.png'), url('#{$imgPath}/bg/bg_orderflow_arrow.png');
// 						background-position: 0 center, right center;
// 					}
// 					&:nth-of-type(4){
// 						background-image: url('#{$imgPath}/bg/bg_orderflow_4.png');
// 						background-position: 0 center;
// 					}
// 					p.step{
// 						@include font-maven;
// 						@include font-size(16);
// 						margin-bottom: 4px;
// 						padding-top: 4px;
// 					}
// 					p.explanation{
// 						@include font-size(13);
// 						@include font-tsukushi-bold;
// 					}
// 				}
// 			}
// 			& > .contact{
// 				display: table;
// 				width: 800px;
// 				margin: 36px auto 0;
// 				table-layout: fixed;
// 				border: 1px solid #333;
// 				border-radius: 2px;
// 				background: transparent url('#{$imgPath}/bg/bg_orderflow_contact.png') left center no-repeat;
// 				background-color: #fff;
// 				background-size: contain;
// 				.logo{
// 					display: table-cell;
// 					width: 200px;
// 					padding-right: 30px;
// 					text-align: center;
// 					vertical-align: middle;
// 				}
// 				.information{
// 					display: table-cell;
// 					width: 600px;
// 					padding: 10px 10px 10px 18px;
// 					text-align: center;
// 					vertical-align: middle;
// 					&.sp{
// 						p{
// 							&.contact{
// 								span.tel{
// 									@include font-size(26);
// 									letter-spacing: 0;
// 								}
// 							}
// 						}
// 					}
// 					p{
// 						@include font-tsukushi-bold;
// 						@include font-size(13);
// 						margin-bottom: 10px;
// 						// letter-spacing: .33em;
// 						&.contact{
// 							margin-bottom: 0;
// 							span.tel{
// 								@include font-size(28);
// 								@include font-maven-bold;
// 								font-weight: bold;
// 								display: inline-block;
// 								margin: 0 2px;
// 								vertical-align: -12%;
// 								letter-spacing: 2px;
// 								color: $brand_red;
// 							}
// 							span.mail{
// 								a{
// 									@include font-size(12);
// 									@include font-tsukushi-bold;
// 									font-weight: bold;
// 									display: inline-block;
// 									margin: 0 2px;
// 									padding: 6px 12px 6px 44px;
// 									text-align: center;
// 									text-align: center;
// 									vertical-align: -12%;
// 									text-decoration: none;
// 									letter-spacing: 2px;
// 									color: $brand_black;
// 									border: 1px solid #333;
// 									background: #f3f2ef url('#{$imgPath}/top/contact_mail.png') 14px center no-repeat;
// 									&:hover{
// 										background-color: darken($brand_white, 10%);
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

section.orderflow{
	@include section-padding;
	@media #{$sp}{
		padding-bottom: 0 !important;
	}
	& > .wrap{
		& > .content{
		}
	}
	.h3_orderflow{
		margin-bottom: 42px;
		text-align: center;
		h3{
			@include font-tsukushi-bold;
			@include font-size(20);
			@include letter-spacing(20, .71);
			font-weight: 700;
			line-height: 1;
			display: inline-block;
			padding: 12px 48px;
			color: #b5181f;
			background-image: url('#{$imgPath}/top/of-heading-left.svg'), url('#{$imgPath}/top/of-heading-right.svg');
			background-repeat: no-repeat, no-repeat;
			background-position: left center, right center;
			background-size: contain, contain;
			@media #{$sp}{
				@include font-size-sp(18);
				padding: vsp(12) vsp(30);
			}
		}
	}
	.orderflow-c{
		width: 800px;
		margin: 0 auto;
		@media #{$sp}{
			width: 100%;
		}
	}
	.orderflow-i{
		width: 100%;
		margin-bottom: 60px;
		@media #{$sp}{
			position: relative;
			margin-bottom: vsp(30);
			padding-bottom: vsp(60);
			&:after{
				position: absolute;
				bottom: 0;
				left: 50%;
				display: block;
				width: vsp(26);
				height: vsp(30);
				content: '';
				transform: translateX(-50%);
				background: transparent url('#{$imgPath}/top/of-icon-arrow.svg') no-repeat center center;
				background-size: auto vsp(30);
			}
		}
		&:last-child{
			margin-bottom: 0;
			@media #{$sp}{
				margin-bottom: 0;
				padding-bottom: 0;
				&:after{
					display: none;
				}
			}
			.orderflow-b{
				&:after{
					display: none;
				}
			}
		}
	}
	.orderflow-b{
		position: relative;
		width: 100%;
		margin-bottom: 30px;
		padding-bottom: 56px;
		@media #{$sp}{
			margin-bottom: 20px;
			padding-bottom: 0;
		}
		&:after{
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 26px;
			height: 30px;
			content: '';
			background: transparent url('#{$imgPath}/top/of-icon-arrow.svg') no-repeat center center;
			@media #{$sp}{
				display: none;
			}
		}
		p{
			@include font-tsukushi;
			@include font-size(14);
			@include letter-spacing(14, .88);
			font-weight: 400;
			line-height: 1.8;
			color: #343434;
			.caption{
				@include font-size(12);
				@include letter-spacing(12, .88);
			}
			& + p{
				margin-top: .75em;
			}
		}
	}
	.orderflow-d{
		width: calc( 100% - 46px );
		margin-top: -60px;
		margin-left: 46px;
		background-color: #fff;
		@media #{$sp}{
			width: 100%;
			margin-top: 0;
			margin-left: 0;
		}
	}
	h5{
		@include font-tsukushi-bold;
		@include font-size(18);
		@include letter-spacing(18, .6);
		font-weight: 700;
		margin-bottom: 30px;
		text-align: left;
		color: #343434;
		@media #{$sp}{
			@include font-size-sp(16);
		}
	}
	.estimate-c{
		@include flex;
		flex-direction: column;
		padding: 24px;
		border: 1px solid #dadada;
		border-radius: 6px;
		@media #{$sp}{
			padding: vsp(16) vsp(12);
		}
	}
	.estimate-i{
		@include flex;
		align-items: flex-start;
		width: 100%;
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid #dadada;
		@media #{$sp}{
			flex-direction: column;
			margin-bottom: 24px;
			padding-bottom: 24px;
		}
		&:last-child{
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
		figure{
			width: 260px;
			img{
				width: 100%;
				max-width: 100%;
			}
			@media #{$sp}{
				width: 100%;
				margin-bottom: 16px;
			}
		}
	}
	.estimate-b{
		flex: 1;
		padding-left: 20px;
		@media #{$sp}{
			padding-left: 0;
		}
		dl{
			@include flex;
			align-items: flex-start;
			flex-wrap: wrap;
			dt,
			dd{
				@include font-size(13);
				@include letter-spacing(13, .46);
				font-weight: 400;
				line-height: 1.6;
				margin-bottom: 16px;
			}
			dt{
				width: 80px;
				text-align: center;
			}
			dd{
				width: calc( 100% - 80px );
				padding-left: 16px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			dt.customer{
				@include font-tsukushi-bold;
				@include letter-spacing(13, .46);
				font-weight: 700;
				color: #b5091f;
				border: 1px solid #b5091f;
				background-color: #fff;
			}
			dt.stuff{
				@include font-tsukushi-bold;
				@include letter-spacing(13, .46);
				font-weight: 700;
				color: #777;
				border: 1px solid #777;
				background-color: #fff;
			}
			dd{
				@include font-tsukushi;
				@include font-size(13);
				@include letter-spacing(13, .46);
				font-weight: 400;
				line-height: 1.6;
				color: #343434;
			}
		}
	}
	.hearing-c{
		@include flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 24px;
		border: 1px solid #dadada;
		border-radius: 6px;
		@media #{$sp}{
			padding: vsp(16) vsp(12);
		}
	}
	.hearing-i{
		width: calc( ( 100% - 32px ) / 3 );
		@media #{$sp}{
			width: calc( ( 100% - 4vw ) / 3 );
		}
		figure{
			width: 100%;
			img{
				width: 100%;
				max-width: 100%;
			}
		}
		figcaption{
			@include font-size(14);
			@include letter-spacing(14, .5);
			@include font-tsukushi;
			font-weight: 400;
			line-height: 1.6;
			margin-top: 8px;
			text-align: center;
			color: #52260c;
		}
	}
	.due-c{
		padding: 12px 0;
		border: 1px solid #dadada;
		border-radius: 6px;
		@media #{$sp}{
			padding-right: vsp(12);
			padding-left: vsp(12);
		}
	}
	.due-i{
		@include flex;
		@media #{$sp}{
			flex-direction: column;
		}
		.title{
			@include font-tsukushi-bold;
			@include font-size(14);
			@include letter-spacing(14, .88);
			font-weight: 700;
			line-height: 1.8;
			padding: 0 20px;
			color: #343434;
			@media #{$sp}{
				width: 100%;
				margin-bottom: vsp(6);
				padding: 0 0 vsp(6) 0;
				border-bottom: 1px solid #dadada;
			}
		}
		.contents{
			flex: 1;
			padding: 0 20px;
			border-left: 1px solid #dadada;
			@media #{$sp}{
				width: 100%;
				padding: 0;
				border-left: none;
			}
			ul{
				li{
					@include font-tsukushi-bold;
					@include font-size(14);
					@include letter-spacing(14, .88);
					font-weight: 700;
					line-height: 1.8;
					color: #343434;
					@media #{$sp}{
						@include font-size(13);
						@include letter-spacing(13, .5);
					}
				}
			}
			.caption{
				@include font-tsukushi;
				@include font-size(12);
				@include letter-spacing(12, .75);
				font-weight: 400;
				line-height: 1.6;
				color: #343434;
				@media #{$sp}{
					margin-top: vsp(4);
				}
			}
		}
	}
	.heading{
		@include flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 16px;
		@media #{$sp}{
			justify-content: center;
		}
		i{
			width: 52px;
			min-width: 52px;
			margin-right: 20px;
			text-align: center;
		}
		.text{
			@include flex;
			align-items: flex-start;
			flex-direction: column;
		}
		.flow{
			@include font-size(12);
			@include letter-spacing(12, .75);
			@include font-maven;
			font-weight: 400;
			margin-bottom: 8px;
			padding: 4px 12px;
			text-transform: uppercase;
			color: #fff;
			border-radius: 20px;
			background-color: #b51820;
		}
		h4{
			@include font-size(20);
			@include font-tsukushi-bold;
			@include letter-spacing(20, .71);
			font-weight: 700;
			color: #343434;
			@media #{$sp}{
				@include font-size-sp(18);
			}
		}
	}
	.orderflow-contact-c{
		@include flex;
		justify-content: center;
		margin-top: 60px;
	}
	.orderflow-contact-i{
		@include flex;
		overflow: hidden;
		align-items: center;
		justify-content: flex-start;
		margin: 0 auto;
		border: 1px solid #333;
		border-radius: 6px;
		@media #{$sp}{
			flex-direction: column;
		}
	}
	.orderflow-contact-logo{
		position: relative;
		width: 185px+43px;
		margin: 0;
		padding: 0 43px 0 20px;
		background-size: cover;
		@media #{$sp}{
			width: 100%;
			padding: vsp(24) 0 vsp(12);
			text-align: center;
		}
		&:after{
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 300px;
			height: 300px;
			margin-left: -100px;
			content: '';
			transform: translate(-50%, -50%) rotate(45deg);
			border: 1px solid #333;
			@media #{$sp}{
				display: none;
			}
		}
		img{
			width: 145px;
		}
	}
	.orderflow-contact-body{
		margin: 0;
		padding: 10px 20px 10px 20px;
		@media #{$sp}{
			padding: vsp(12);
		}
		.lead{
			@include font-size(14);
			@include font-tsukushi-bold;
			@include letter-spacing(14, .88);
			font-weight: 700;
			line-height: 1.6;
			color: #343434;
			br{
				display: none;
				@media #{$sp}{
					display: inline-block;
				}
			}
			@media #{$sp}{
				text-align: center;
			}
		}
	}
	.orderflow-contact-list{
		@include flex;
		justify-content: flex-start;
		margin-top: 8px;
		@media #{$sp}{
			flex-direction: column;
			justify-content: center;
		}
		p{
			line-height: 1.6;
			@media #{$sp}{
				width: 100%;
			}
			&.tel{
				a{
					@include flex;
					@include font-maven;
					@include font-size(20);
					@include letter-spacing(20, 2.2);
					font-weight: 400;
					align-items: center;
					min-height: 32px;
					padding-right: 20px;
					padding-left: 20px;
					transition: 300ms;
					text-decoration: none;
					color: #fff;
					border-radius: 4px;
					background-color: #b5181f;
					@media #{$sp}{
						justify-content: center;
						width: 100%;
						margin-bottom: vsp(8);
					}
					.i{
						margin-right: 12px;
					}
					&:hover{
						transition: 300ms;
						background-color: lighten(#b5181f, 10%);
					}
				}
			}
			&.or{
				@include font-size(14);
				@include letter-spacing(14, .88);
				@include font-tsukushi-bold;
				font-weight: 700;
				padding-right: 8px;
				padding-left: 8px;
				color: #343434;
				@media #{$sp}{
					margin-bottom: vsp(8);
					text-align: center;
				}
			}
			&.form{
				a{
					@include flex;
					@include font-tsukushi-bold;
					@include font-size(16);
					@include letter-spacing(16, .89);
					font-weight: 400;
					align-items: center;
					min-height: 32px;
					padding-right: 20px;
					padding-left: 20px;
					transition: 300ms;
					text-decoration: none;
					color: #fff;
					border-radius: 4px;
					background-color: #333;
					@media #{$sp}{
						justify-content: center;
						width: 100%;
					}
					.i{
						margin-right: 12px;
					}
					&:hover{
						transition: 300ms;
						background-color: lighten(#333, 10%);
					}
				}
			}
		}
	}
}




// catch ===================================================*/
section.showcase{
	@include section-padding;
	background: transparent url('#{$imgPath}/bg/bg_showcase.jpg') center center no-repeat;
	background-size: cover;
	@media #{$sp}{
		background: transparent url('#{$imgPath}/bg/bg_showcase@2x.jpg') center center repeat-y;
		background-size: cover;
	}
	& > .wrap{
		& > .content{
			@extend %gallerycol4;
			position: relative;
			ul{
				@extend %clearfix;
				width: 100%;
				margin-bottom: 42px;
				border: 1px solid #333;
				border-radius: 2px;
				@media #{$sp}{
					@include flex;
					flex-wrap: wrap;
					// flex-direction: column;
				}
				li{
					float: left;
					width: 33.3333333%;
					text-align: center;
					border-right: 1px solid #333;
					@media #{$sp}{
						width: 100%;
						border-right: 0;
						border-bottom: 1px solid #333;
						&.all{
							width: 100%;
						}
						&.reform{
							width: 50%;
						}
						&.furniture{
							width: 50%;
							border-right: 1px solid #333;
							border-bottom: none;
						}
						&:last-child{
							border-bottom: none;
						}
					}
					&.active,
					&.current{
						a{
							background-color: rgba(196,196,193,.3);
						}
					}
					&.inactive{
						a{
							background-color: transparent;
						}
					}
					& > a{
						@include font-size(14);
						@include font-tsukushi-bold;
						display: block;
						width: 100%;
						padding: 18px 0;
						cursor: pointer;
						text-decoration: none;
						letter-spacing: 3px;
						color: #333;
						@media #{$sp}{
							@include font-size-sp(16);
							letter-spacing: .1em;
						}
					}
					span{
						padding-left: 32px;
						background-repeat: no-repeat;
						background-position: left center;
						@media #{$sp}{
							padding-left: 24px;
						}
						&.all{
							background-image: url('#{$imgPath}/top/icon_all.png');
						}
						&.reform{
							background-image: url('#{$imgPath}/top/icon_reform.png');
						}
						&.furniture{
							background-image: url('#{$imgPath}/top/icon_furniture.png');
						}
					}
					&:last-child{
						border-right: none;
					}
				}
			}
		}
		.js_grid{
			// overflow: hidden;
		}
		.button{
			@extend %clearfix;
			padding: 48px 0 36px;
			text-align: center;
			@media #{$sp}{
				@include flex;
				flex-direction: column;
				width: 100%;
			}
			a{
				@include font-tsukushi-bold;
				@include font-size(16);
				font-weight: bold;
				line-height: 1;
				float: left;
				width: 50%;
				padding: 18px 36px;
				text-align: center;
				text-decoration: none;
				letter-spacing: 3px;
				color: #333;
				border: 1px solid #333;
				border-radius: 2px;
				@media #{$sp}{
					width: 100%;
				}
				span{
					padding-left: 32px;
					background-repeat: no-repeat;
					background-position: left center;
				}
				&.reform_more{
					border-left: none;
					border-radius: 0 2px 2px 0;
					@media #{$sp}{
						border-left: 1px solid #333;
						border-radius: 0 0 2px 2px;
					}
					span{
						letter-spacing: 1px;
						background-image: url('#{$imgPath}/top/icon_reform.png');
						@media #{$sp}{
							background-image: url('#{$imgPath}/top/icon_reform.svg');
						}
					}
				}
				&.furniture_more{
					border-radius: 2px 0 0 2px;
					@media #{$sp}{
						border-bottom: none;
						border-radius: 2px 2px 0 0;
					}
					span{
						letter-spacing: 1px;
						background-image: url('#{$imgPath}/top/icon_furniture.png');
						@media #{$sp}{
							background-image: url('#{$imgPath}/top/icon_furniture.svg');
						}
					}
				}
				&:hover{
					background-color: rgba(196,196,193,.3);
				}
			}
		}
	}
}

// catch ===================================================*/
// section.onlineshop{
// 	@include section-padding;
// 	padding-bottom: 150px !important;
// 	background: transparent url('#{$imgPath}/bg/bg_onlineshop.jpg') center center no-repeat;
// 	background-size: cover;
// 	& > .wrap{
// 		& > .content{
// 			.lead{
// 				float: left;
// 				width: 470px;
// 				margin-top: 26px;
// 				p{
// 					@include font-size(14);
// 					line-height: 1.53;
// 					letter-spacing: 1px;
// 					& + p{
// 						margin-top: 14px;
// 					}
// 				}
// 				.button_onlineshop{
// 					margin-top: 42px;
// 					a{
// 						@include font-size(16);
// 						@include font-tsukushi-bold;
// 						display: block;
// 						width: 100%;
// 						padding: 20px 0;
// 						text-align: center;
// 						text-decoration: none;
// 						letter-spacing: 3px;
// 						color: #fff;
// 						background-color: $brand_red;
// 						&:hover{
// 							background-color: lighten($brand_red, 20%);
// 						}
// 						span{
// 							display: inline-block;
// 							min-height: 18px;
// 							padding: 0 26px 0 32px;
// 							background-image: url('#{$imgPath}/top/icon_shoppingbag.png'), url('#{$imgPath}/bg/bg_arrow_white.png');
// 							background-repeat: no-repeat, no-repeat;
// 							background-position: left center, right center;
// 						}
// 					}
// 				}
// 			}
// 			.gallery{
// 				float: right;
// 				width: 472px;
// 				ul{
// 					@extend %clearfix;
// 					width: 100%;
// 					li{
// 						float: left;
// 						width: 128px;
// 						margin-right: 44px;
// 						&:last-child{
// 							margin-right: 0;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// section.onlineshop.update20171205{
// 	@include section-padding;
// 	padding-bottom: 150px !important;
// 	background: transparent url('#{$imgPath}/bg/bg_onlineshop_2.jpg') center center no-repeat;
// 	background-size: cover;
// 	& > .wrap{
// 		& > .content{
// 			.lead{
// 				float: left;
// 				width: 480px;
// 				margin-top: 26px;
// 				p{
// 					@include font-size(14);
// 					line-height: 1.53;
// 					letter-spacing: 1px;
// 					& + p{
// 						margin-top: 14px;
// 					}
// 				}
// 				.button_onlineshop{
// 					margin-top: 42px;
// 					text-align: center;
// 					a{
// 						@include font-size(16);
// 						@include font-tsukushi-bold;
// 						display: block;
// 						width: 470px;
// 						margin: 0 auto;
// 						padding: 20px 0;
// 						text-align: center;
// 						text-decoration: none;
// 						letter-spacing: 3px;
// 						color: #fff;
// 						background-color: $brand_red;
// 						&:hover{
// 							background-color: lighten($brand_red, 20%);
// 						}
// 						span{
// 							display: inline-block;
// 							min-height: 18px;
// 							padding: 0 26px 0 32px;
// 							background-image: url('#{$imgPath}/top/icon_shoppingbag.png'), url('#{$imgPath}/bg/bg_arrow_white.png');
// 							background-repeat: no-repeat, no-repeat;
// 							background-position: left center, right center;
// 						}
// 					}
// 				}
// 			}
// 			.gallery{
// 				float: right;
// 				width: 476px;
// 				img{
// 					border: 3px solid #fff;
// 					box-shadow: 0 2px 5px rgba(0,0,0,.1);
// 				}
// 			}
// 		}
// 	}
// }

// catch ===================================================*/
section.access{
	@include section-padding;
	& > .wrap{
		& > .content{
			@media #{$sp}{
				@include flex;
				flex-direction: column;
			}
			.map{
				@extend %clearfix;
				float: left;
				width: 600px;
				@media #{$sp}{
					float: none;
					order: 2;
					width: 100%;
				}
				.googlemap{
					margin-bottom: 14px;
					iframe{
						@media #{$sp}{
							max-width: 100%;
						}
					}
				}
				p{
					@include font-size(12);
					line-height: 1.5;
					float: left;
					width: 360px;
					@media #{$sp}{
						float: none;
						width: 100%;
						margin-bottom: 14px;
					}
				}
				.button_map{
					float: right;
					width: 240px;
					@media #{$sp}{
						float: none;
						width: 100%;
					}
					a{
						@include font-tsukushi;
						@include font-size(16);
						display: block;
						width: 100%;
						padding: 16px 0;
						text-align: center;
						color: #333;
						border: 2px solid #333;
						&:hover{
							span{
								background-position: left 10px center, right 4px center;
							}
						}
						span{
							display: inline-block;
							min-height: 18px;
							padding: 0 46px 0 46px;
							background-image: url('#{$imgPath}/top/icon_company_map.png'), url('#{$imgPath}/top/icon_company_black.png');
							background-repeat: no-repeat, no-repeat;
							background-position: left 10px center, right 18px center;
						}
					}
				}
			}
			.storeinfo{
				float: right;
				width: 380px;
				@media #{$sp}{
					float: none;
					order: 1;
					width: 100%;
					margin-bottom: vsp(20);
				}
				p.company{
					// @include font-tsukushi-bold;
					@include font-tsukushi;
					@include font-size(20);
					line-height: 1.54;
					margin-bottom: 4px;
				}
				p.address{
					@include font-size(13);
					line-height: 1.54;
				}
				hr{
					display: block;
					height: 0;
					margin: 11px 0;
					padding: 0;
					color: #fff;
					border-top: 1px solid #ececec;
					border-right: none;
					border-bottom: none;
					border-left: none;
				}
				a.tel,
				p.fax{
					@include font-maven;
					@include font-size(24);
					padding: 0 0 0 32px;
					text-decoration: none;
					color: #333;
					background-repeat: no-repeat;
					background-position: 4px center;
				}
				a.tel{
					display: block;
					margin-bottom: 12px;
					background-image: url('#{$imgPath}/top/icon_company_tel.png');
				}
				p.fax{
					background-image: url('#{$imgPath}/top/icon_company_fax.png');
				}
				.button{
					margin-top: 20px;
					& + .button{
						margin-top: 12px;
					}
					a{
						@include font-tsukushi-bold;
						@include font-size(16);
						display: block;
						width: 100%;
						padding: 20px 0;
						text-align: center;
						text-decoration: none;
						letter-spacing: 2px;
						color: #fff;
						span{
							padding: 0 32px 0 42px;
							background-repeat: no-repeat, no-repeat;
						}
					}
				}
				.button_company{
					a{
						background-color: $brand_red;
						span{
							background-image: url('#{$imgPath}/top/icon_company_building.png'), url('#{$imgPath}/bg/bg_arrow_white.png');
							background-position: 8px center, right center;
						}
						&:hover{
							background-color: lighten($brand_red, 20%);
						}
					}
				}
				.button_contact{
					a{
						background-color: $brand_black;
						span{
							background-image: url('#{$imgPath}/top/icon_company_mail.png'), url('#{$imgPath}/bg/bg_arrow_white.png');
							background-position: 0 center, right center;
						}
						&:hover{
							background-color: lighten($brand_black, 20%);
						}
					}
				}
			}
		}
	}
}
