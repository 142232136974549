// taxonomy ===================================================*/
article.taxonomy{
	section.gallery_col_3{
		padding: 0;
		.wrap{
			.content{
				@extend %gallery_col_3;
			}
		}
	}
}