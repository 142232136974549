// company ===================================================*/
article.contact{
	section.orderflow{
		.orderflow-contact-c{
			display: none;
		}
	}
	section.contact{
		.wrap{
			.content{
			}
		}
		.lead{
			margin-bottom: 40px;
			p{
				@include font-size(16);
				line-height: 1.88;
				margin-bottom: 40px;
				text-align: center;
				letter-spacing: 4px;
				@media #{$sp}{
					@include font-size(14);
					margin-bottom: vsp(30);
					text-align: left;
					letter-spacing: .1em;
					br{
						display: none;
						&.sp{
							display: inline-block;
						}
					}
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.form_content{
			table{
				@extend %table_style;
				float: none;
				width: 100%;
				tbody{
					tr{
						@media #{$sp}{
							display: block;
							width: 100%;
						}
						th,
						td{
							@include font-size(16);
						}
						th{
							width: 40%;
							@media #{$sp}{
								display: block;
								width: 100%;
								padding-bottom: 8px;
							}
						}
						td{
							width: 60%;
							@media #{$sp}{
								display: block;
								width: 100%;
								padding-top: 8px;
							}
						}
					}
				}
			}
		}
		.button{
			margin-top: 20px;
			& + .button{
				margin-top: 12px;
			}
			a{
				@include font-tsukushi-bold;
				@include font-size(16);
				display: block;
				width: 100%;
				padding: 20px 0;
				text-align: center;
				text-decoration: none;
				letter-spacing: 2px;
				color: #fff;
				span{
					padding: 0 32px 0 42px;
					background-repeat: no-repeat, no-repeat;
				}
			}
		}
		.button_company{
			a{
				background-color: $brand_red;
				span{
					background-image: url('#{$imgPath}/top/icon_company_building.png'), url('#{$imgPath}/bg/bg_arrow_white.png');
					background-position: 8px center, right center;
				}
				&:hover{
					background-color: lighten($brand_red, 20%);
				}
			}
		}
	}
}
