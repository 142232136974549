// html,
// body{
// 	width: 100%;
// 	height: 100%;
// 	margin: 0;
// 	padding: 0;
// }

html{
	width: 100%;
	min-width: 1040px;
	@media #{$sp}{
		min-width: initial;
		min-width: auto;
	}
}

// layout ===================================================*/
body{
	// -webkit-font-smoothing: antialiased;
	@include placeholder(#aaa);
	font-family: fot-tsukuardgothic-std, 'FOT-筑紫A丸ゴシック Std M', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', Osaka, 'メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic', Geneva, Arial, Verdana, sans-serif;
	font-size: 13px;
	letter-spacing: .03em;
	color: $text_default;
	background: transparent none center top repeat;

	-webkit-text-size-adjust: 100%;
}

// model ===================================================*/
.wrapper{
	// padding-top: 149px;
	& > .page{
		width: 100%;
		transform: rotate(.001deg);
		& main{
			width: 100%;
			&.archive,
			&.single{
				@extend %clearfix;
				width: 1000px;
				margin: 0 auto;
				padding: 30px 0;
				@media #{$sp}{
					width: 100%;
				}
				& > article{
					float: left;
					width: 730px;
					@media #{$sp}{
						float: none;
						width: 100%;
						padding-right: vsp(16);
						padding-left: vsp(16);
					}
					& > section{
						width: 100%;
						& > .wrap{
							width: 730px;
							margin: 0 auto;
							@media #{$sp}{
								width: 100%;
							}
							& > .content{
								@extend %clearfix;
								width: 730px;
								@media #{$sp}{
									width: 100%;
								}
							}
						}
					}
				}
				& > aside{
					float: right;
					width: 230px;
					@media #{$sp}{
						float: none;
						width: 100%;
						padding-right: vsp(16);
						padding-left: vsp(16);
					}
				}
			}
			&.page{
				section{
					width: 100%;
					& > .wrap{
						width: 1000px;
						margin: 0 auto;
						@media #{$sp}{
							width: 100%;
							padding-right: vsp(16);
							padding-left: vsp(16);
						}
						& > .content{
							@extend %clearfix;
							width: 1000px;
							@media #{$sp}{
								width: 100%;
							}
						}
					}
				}
			}
			& > section{
				width: 100%;
				& > .wrap{
					width: 1000px;
					margin: 0 auto;
					@media #{$sp}{
						width: 100%;
						padding-right: vsp(16);
						padding-left: vsp(16);
					}
					& > .content{
						@extend %clearfix;
						width: 1000px;
						@media #{$sp}{
							width: 100%;
						}
					}
				}
			}
		}
	}
}

// section common ===================================================*/
section{
	padding: 48px 0;
	background-color: #fff;
	@media #{$sp}{
		padding: vsp(40) 0;
	}
}

a{
	transition: 300ms;
	img{
		transition: 300ms;
	}
	&:hover{
		transition: 300ms;
		img{
			transition: 300ms;
		}
	}
}
