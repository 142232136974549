// text color ===================================================*/
$text_default: #333333;
$text_link: #333333;
$text_hover: #333333;
$text_active: #333333;

// color ===================================================*/
$brand_red: #b5091f;
$brand_brown: #582c14;
$brand_darkbrown: #54270e;
$brand_ocher: #716d60;
$brand_black: #333333;
$brand_gray: #666666;
$brand_white: #f3f2ef;

// image folder path ===================================================*/
$imgPath: '../../images';