/* h2 ===================================================*/
.h2{
	margin-bottom: 42px;
	padding: 14px 22px 20px 36px;
	border-top: 1px solid #bcbcbc;
	background-image: url('#{$imgPath}/headline/h2_border_bottom.png'), url('#{$imgPath}/headline/h2_arrow.png');
	background-repeat: no-repeat, no-repeat;
	background-position: center bottom, 12px 13px;
	@media #{$sp}{
		padding: vsp(16) vsp(16) vsp(20) vsp(36);
		background-image: url('#{$imgPath}/headline/h2_border_bottom.png'), url('#{$imgPath}/headline/h2_arrow.svg');
		background-position: center bottom, vsp(12) vsp(13);
	}
	p{
		display: inline-block;
		margin-bottom: 0 !important;
	}
	h2{
		@include font-merriweather;
		@include font-size(20);
		font-weight: 300;
		display: inline-block;
		letter-spacing: 4px;
		text-transform: uppercase;
		color: $brand_black;
		@media #{$sp}{
			@include font-size-sp(18);
		}
	}
	span{
		@include font-size(12);
		@include font-hannari;
		display: inline-block;
		margin-left: 10px;
		letter-spacing: 1px;
		@media #{$sp}{
			@include font-size-sp(12);
		}
	}
	&.h2_catch{
		margin: 0 0 36px 0;
		padding-bottom: 36px;
		// padding-bottom: 0 0 36px 0;
		text-align: center;
		border-top: none;
		background: transparent url('#{$imgPath}/catch/balloon.png') center bottom no-repeat;
		@media #{$sp}{
			padding: 0 0 36px 0;
		}
		h2{
			padding-left: 18px;
			@media #{$sp}{
				padding-left: 0;
			}
			span.title{
				@include font-hannari;
				@include font-size(64);
				@include font-smoothing(antialiased);
				margin-bottom: 16px;
				margin-left: 0;
				letter-spacing: normal;
				color: #fff;
				@media #{$sp}{
					@include font-size-sp(42);
					padding-left: 0;
					text-indent: .5em;
				}
			}
			span.lead{
				@include font-size(38);
				@include font-smoothing(antialiased);
				margin-left: 0;
				letter-spacing: -3px;
				color: #fff;
				br.sp{
					display: none;
					@media #{$sp}{
						display: inline-block;
					}
				}
				@media #{$sp}{
					@include font-size-sp(24);
					line-height: 1.6;
					letter-spacing: -.1em;
				}
			}
		}
	}
	&.h2_proposal{
		margin: 0 0 35px 0;
		padding: 0;
		border-top: none;
		background-image: none;
		@media #{$sp}{
			width: 93.86666666%;
			margin: 0 auto 35px auto;
		}
		h2{
			padding: 0;
		}
	}
	&.h2_archive{
		margin: 0 0 20px 0;
	}
	&.h2_post{
		margin-bottom: 15px;
		padding: 0;
		border: none;
		background: none;
		h2{
			@include font-tsukushi-bold;
			@include font-size(26);
			line-height: 1.46;
			text-transform: none;
			color: $brand_black;
			@media #{$sp}{
				@include font-size-sp(26);
				letter-spacing: normal;
			}
		}
	}
	&.h2_company,
	&.h2_taxonomy{
		margin: 0 0 20px 0;
		text-transform: none;
		h2{
			@include font-size(20);
			@include font-hannari;
			text-transform: none;
			@media #{$sp}{
				@include font-size-sp(18);
			}
		}
	}
}

/* h3 ===================================================*/
.h3{
	h3{
	}
	span{
	}
	&.h3_onlineshop{
		margin-bottom: 25px;
		h3{
			@include font-size(34);
			@include font-size(26);
			@include font-roboto;
			font-weight: 700;
			line-height: 1.35;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: $brand_red;
		}
		span{
			@include font-roboto;
			color: #1d8fd0;
		}
		span.en{
			@include font-roboto;
			@include font-size(26);
			text-transform: uppercase;
			color: #b5091f;
		}
		span.ja{
			@include font-size(20);
			@include font-tsukushi-bold;
			color: #1d8fd0;
		}
	}
}

/* h5 ===================================================*/
.h5{
	h5{
	}
	span{
	}
	&.h5_recent{
		display: inline-block;
		margin: 0 auto 40px;
		padding: 0 60px;
		text-align: center;
		background-image: url('#{$imgPath}/bg/bg_h5_recent_left.png'), url('#{$imgPath}/bg/bg_h5_recent_right.png');
		background-repeat: no-repeat, no-repeat;
		background-position: left center, right center;
		@media #{$sp}{
			margin: 0 auto vsp(30);
			padding: 0 vsp(50);
			background-image: url('#{$imgPath}/bg/bg_h5_recent_left.svg'), url('#{$imgPath}/bg/bg_h5_recent_right.svg');
			background-size: contain, contain;
		}
		h5{
			@include font-size(20);
			@include font-merriweather;
			font-weight: 300;
			display: inline-block;
			padding: 4px 0;
			letter-spacing: 6px;
			text-transform: uppercase;
			color: #333;
			@media #{$sp}{
				letter-spacing: .2em;
			}
		}
		span{
			@include font-hannari;
			@include font-size(12);
			display: block;
			padding: 0 0 4px;
			letter-spacing: 2px;
			color: #333;
			@media #{$sp}{
				letter-spacing: .1em;
			}
		}
	}
}

/* h6 ===================================================*/
.h6{
	h6{
	}
	span{
	}
	&.h6_category{
		margin-bottom: 4px;
		padding: 12px 0 8px;
		text-align: center;
		background-image: url('#{$imgPath}/headline/h6_edge_a.png'), url('#{$imgPath}/headline/h6_edge_b.png'), url('#{$imgPath}/headline/h6_edge_c.png'), url('#{$imgPath}/headline/h6_edge_d.png'), url('#{$imgPath}/bg/bg_h6.png');
		background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
		background-position: left top, right top, right bottom, left bottom;
		background-size: auto, auto, auto, auto, cover;
		@media #{$sp}{
			margin-bottom: 6px;
		}
		h6{
			@include font-merriweather;
			@include font-size(16);
			font-weight: 300;
			display: inline-block;
			padding-left: 26px;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
		span{
			@include font-hannari;
			@include font-size(11);
			display: block;
			margin-top: 4px;
			text-align: center;
			letter-spacing: normal;
		}
		&.reform{
			h6{
				background: transparent url('#{$imgPath}/aside/icon_reform.png') left center no-repeat;
			}
		}
		&.furniture{
			h6{
				background: transparent url('#{$imgPath}/aside/icon_furniture.png') left center no-repeat;
			}
		}
	}
	&.h6_orderflow{
		margin-bottom: 4px;
		padding: 16px 12px 26px 8px;
		text-align: center;
		background: transparent url('#{$imgPath}/aside/icon_orderflow_arrow.png') center bottom no-repeat;
		h6{
			@include font-merriweather;
			@include font-size(20);
			font-weight: 300;
			display: inline-block;
			padding-left: 3px;
			letter-spacing: 3px;
			text-transform: uppercase;
		}
		span{
			@include font-hannari;
			@include font-size(12);
			display: block;
			margin-top: 4px;
			text-align: center;
		}
	}
}
