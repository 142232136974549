/*--------------------------------------------------
このCSSはGulp+SCSSを使ってコンパイルしています。
CSSの内容を変更してもSCSSを変更しない限り差分が発生するため
かならずSCSSにも追記をお願いします。
 --------------------------------------------------*/
@charset 'UTF-8';

/* assets ===================================================*/
@import 'asset/reset';
@import 'asset/mediaquery';
@import 'asset/config';
@import 'asset/variables';
@import 'asset/fonts';
@import 'asset/mixins';
@import 'asset/extend';
@import 'asset/form';
@import 'asset/effect';

/* model ===================================================*/
@import 'model/layout';
@import 'model/header';
@import 'model/footer';
@import 'model/nav';
@import 'model/aside';
@import 'model/headline';

/* jquery ===================================================*/
// @import 'jquery/jquery.megamenu';
// @import 'jquery/jquery.owl.carousel';
// @import 'jquery/jquery.ui.tabs';

/* page ===================================================*/
@import 'page/top';
@import 'page/company';
@import 'page/contact';
@import 'page/orderflow';
@import 'page/contactform7';
@import 'page/mw_wp_form';


/* archive ===================================================*/
@import 'archive/archive';

/* single ===================================================*/
@import 'single/single';

/* taxonomy ===================================================*/
@import 'taxonomy/taxonomy';