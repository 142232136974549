// Google WebFont Droid ===================================================*/
// @import url(http://fonts.googleapis.com/css?family=Droid+Sans:400,700);
// @mixin font-droid{
// 	font-family: 'Droid Sans';
// }
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Maven+Pro:400,500);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,400);

// Google WebFont Roboto ===================================================*/
@mixin font-roboto{
	font-family: 'Roboto Condensed';
}

@mixin font-merriweather{
	font-family: 'Merriweather';
}

// @import url(https://fonts.googleapis.com/earlyaccess/hannari.css);
@font-face{
	font-family: 'hannari';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/hannari/hannari-webfont.eot?') format('eot'),
	url('../fonts/hannari/hannari-webfont.woff2') format('woff2'),
	url('../fonts/hannari/hannari-webfont.woff') format('woff'),
	url('../fonts/hannari/hannari-webfont.otf')  format('opentype');
}

@mixin font-hannari{
	font-family: 'hannari';
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
}

@mixin font-maven{
	font-family: 'Maven Pro', sans-serif;
}

@mixin font-maven-bold{
	font-family: 'Maven Pro', sans-serif;
	font-weight: bold;
}

@mixin font-tsukushi{
	// font-family: 'FOT-筑紫A丸ゴシック Std D';
	font-family: fot-tsukuardgothic-std, 'FOT-筑紫A丸ゴシック Std D', sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
}

@mixin font-tsukushi-bold{
	// font-family: 'FOT-筑紫A丸ゴシック Std B';
	font-family: fot-tsukuardgothic-std, 'FOT-筑紫A丸ゴシック Std B', sans-serif;
	font-weight: 700;
}
