// // 送信後のページ内遷移の切り替え
// form{
// 	.contact-before-transmission{
// 		display: block;
// 		transition: 300ms;
// 		opacity: 1;
// 	}
// 	.contact-after-transmission{
// 		display: none;
// 		transition: 300ms;
// 		opacity: 0;
// 		/* 確認時は下記をアクティブに */
// 		// display: block;
// 		// transition: 300ms;
// 		// opacity: 1;
// 	}
// 	&.sent{
// 		.contact-before-transmission{
// 			display: none;
// 			transition: 300ms;
// 			opacity: 0;
// 		}
// 		.contact-after-transmission{
// 			display: block;
// 			transition: 300ms;
// 			opacity: 1;
// 		}
// 	}
// }

// // チェックボックス・ラジオボタンのマージン
// span.wpcf7-list-item{
// 	margin: 0 !important;
// }

// // バリテーションエラーの表示
// span.wpcf7-not-valid-tip{
// 	@include font-size(13);
// 	display: block;
// 	margin-top: .5rem;
// 	color: $dark-red;
// }



