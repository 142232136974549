// orderflow ===================================================*/

section.orderflow{
	@include section-padding;
	// _top.scss
}

article.page.orderflow{
	.form_content{
		@extend %post_content;
		@include section-padding;
	}
	.form_content{
		.h2{
			h2{
				display: inline-block;
			}
			p{
				display: inline-block;
				margin-bottom: 0;
			}
		}
		table{
			@extend %table_style;
			float: none;
			width: 100%;
			tbody{
				tr{
					@media #{$sp}{
						display: block;
						width: 100%;
					}
					th,
					td{
						@include font-size(16);
					}
					th{
						width: 40%;
						@media #{$sp}{
							display: block;
							width: 100%;
							padding-bottom: 8px;
						}
					}
					td{
						width: 60%;
						@media #{$sp}{
							display: block;
							width: 100%;
							padding-top: 8px;
						}
					}
				}
			}
		}
	}
}
