// // ボタンの表示
// .form-button{
// 	&.input{
// 		div.mw_wp_form_confirm &,
// 		div.mw_wp_form_complete &{
// 			display: none;
// 		}
// 	}
// 	&.confirm{
// 		div.mw_wp_form_input &,
// 		div.mw_wp_form_complete &{
// 			display: none;
// 		}
// 	}
// 	&.complete{
// 		div.mw_wp_form_input &,
// 		div.mw_wp_form_confirm &{
// 			display: none;
// 		}
// 	}
// }

// // サンキュー画面の表示
// .mw_wp_form.mw_wp_form_input{
// 	.form-c.input,
// 	.form-c.confirm{
// 		display: block;
// 	}
// 	.form-c.complete{
// 		display: none;
// 	}
// }
// .mw_wp_form.mw_wp_form_confirm{
// 	.form-c.input,
// 	.form-c.confirm{
// 		display: block;
// 	}
// 	.form-c.complete{
// 		display: none;
// 	}
// }
// .mw_wp_form.mw_wp_form_complete{
// 	.form-c.input,
// 	.form-c.confirm{
// 		display: none;
// 	}
// 	.form-c.complete{
// 		display: block;
// 	}
// }

// .error-message{
// 	@include font-size(13);
// 	@include font-weight(600);
// 	display: block;
// 	margin-top: 12px;
// 	padding-left: 1.5em;
// 	color: #fa3535;
// 	background: transparent url(../images/icon/alert.svg) center left no-repeat;
// 	background-size: auto 1.2em;
// 	@media #{$sp}{
// 		@include font-size(12);
// 	}
// 	&:empty{
// 		margin-top: 0;
// 	}
// }

// // ラジオボタン変更
// .mwform-radio-field{
// 	&:last-child{
// 		& > label{
// 			margin-right: 0;
// 		}
// 	}
// 	& > label{
// 		@include font-size(16);
// 		@include letter-spacing(16, 1);
// 		margin-right: 20px;
// 		padding: calc( ( 10 / 16 ) * 1em) 0;
// 		input[type='radio']{
// 			display: none;
// 			&:checked{
// 				& + .mwform-radio-field-text{
// 					background: transparent url(../images/contact/radio-on.svg) center left no-repeat;
// 				}
// 			}
// 		}
// 		.mwform-radio-field-text{
// 			padding-left: 2.5em;
// 			background: transparent url(../images/contact/radio-off.svg) center left no-repeat;
// 		}
// 	}
// }

// // チェックボックス変更
// .mwform-checkbox-field{
// 	&:last-child{
// 		& > label{
// 			margin-right: 0;
// 		}
// 	}
// 	& > label{
// 		@include font-size(16);
// 		@include letter-spacing(16, 1);
// 		margin-right: 20px;
// 		padding: calc( ( 10 / 16 ) * 1em) 0;
// 		input[type='checkbox']{
// 			display: none;
// 			&:checked{
// 				& + .mwform-checkbox-field-text{
// 					background: transparent url(../images/contact/checkbox-on.svg) center left no-repeat;
// 				}
// 			}
// 		}
// 		.mwform-checkbox-field-text{
// 			padding-left: 2.5em;
// 			background: transparent url(../images/contact/checkbox-off.svg) center left no-repeat;
// 		}
// 	}
// }
