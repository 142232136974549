// [class*='.effect-']{
// 	-webkit-backface-visibility: hidden;
// 	-moz-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	-webkit-perspective: 1000;
// 	-moz-perspective: 1000;
// 	perspective: 1000;
// }
// .effect-fade{
// 	opacity: 0;
// }
// .effect-fade.in{
// 	opacity: 1;
// }
// .effect-slide-left{
// 	transform: translate3d(-30%, 0, 0);
// 	opacity: 0;
// }
// .effect-slide-left.in{
// 	transform: translate3d(0, 0, 0);
// 	opacity: 1;
// }
// .effect-slide-right{
// 	transform: translate3d(30%, 0, 0);
// 	opacity: 0;
// }
// .effect-slide-right.in{
// 	transform: translate3d(0, 0, 0);
// 	opacity: 1;
// }
// .effect-slide-top{
// 	transform: translate3d(0, -30%, 0);
// 	opacity: 0;
// }
// .effect-slide-top.in{
// 	transform: translate3d(0, 0, 0);
// 	opacity: 1;
// }
// .effect-slide-bottom{
// 	transform: translate3d(0, 30%, 0);
// 	opacity: 0;
// }
// .effect-slide-bottom.in{
// 	transform: translate3d(0, 0, 0);
// 	opacity: 1;
// }
// .effect-pop{
// 	opacity: 0;
// }
// .effect-pop.in{
// 	animation: pop .6s ease-out .5s both;
// 	opacity: 1 \9;
// }
// @-webkit-keyframes pop{
// 	0%{
// 		transform: scale(1);
// 	}
// 	50%{
// 		transform: scale(1.2);
// 		opacity: 1;
// 	}
// 	100%{
// 		transform: scale(1);
// 		opacity: 1;
// 	}
// }
// @-moz-keyframes pop{
// 	0%{
// 		transform: scale(1);
// 	}
// 	50%{
// 		transform: scale(1.2);
// 		opacity: 1;
// 	}
// 	100%{
// 		transform: scale(1);
// 		opacity: 1;
// 	}
// }
// @-o-keyframes pop{
// 	0%{
// 		transform: scale(1);
// 	}
// 	50%{
// 		transform: scale(1.2);
// 		opacity: 1;
// 	}
// 	100%{
// 		transform: scale(1);
// 		opacity: 1;
// 	}
// }
// @keyframes pop{
// 	0%{
// 		transform: scale(1);
// 	}
// 	50%{
// 		transform: scale(1.2);
// 		opacity: 1;
// 	}
// 	100%{
// 		transform: scale(1);
// 		opacity: 1;
// 	}
// }
// .effect-fall{
// 	transform: scale(1.5);
// 	opacity: 0;
// }
// .effect-fall.in{
// 	transform: scale(1);
// 	opacity: 1;
// }
// .effect-perspective{
// 	-webkit-perspective: 1300px;
// 	-moz-perspective: 1300px;
// 	perspective: 1300px;
// }
// .effect-helix{
// 	transform: rotateY(-360deg);
// 	opacity: 0;

// 	transform-style: preserve-3d;
// }
// .effect-helix.in{
// 	transform: rotateY(0);
// 	opacity: 1;
// }

// .effect-role{
// 	transform: scale(.5) rotate(-720deg);
// 	opacity: 0;

// 	transform-style: preserve-3d;
// }
// .effect-role.in{
// 	transform: scale(1) rotate(0);
// 	opacity: 1;
// }



@keyframes FadeOutHeader{
	to{
		transform: scaleY(1);
		transform-origin: center top;
		transition: 200ms;
		opacity: 1;
	}
	from{
		transform: scaleY(0);
		transform-origin: center top;
		transition: 200ms;
		opacity: 0;
	}
}

@keyframes FadeInHeader{
	to{
		transform: scaleY(0);
		transform-origin: center top;
		transition: 200ms;
		opacity: 0;
	}
	from{
		transform: scaleY(1);
		transform-origin: center top;
		transition: 200ms;
		opacity: 1;
	}
}

@mixin FadeOutHeader{
	animation-name: FadeOutHeader;
	animation-duration: 200ms;
	animation-timing-function: ease;
	// animation-delay: 0ms;
	// animation-direction: normal;

// animation-direction: normal;
	// animation-fill-mode: forwards;
}

@mixin FadeInHeader{
	animation-name: FadeOutHeader;
	animation-duration: 200ms;
	animation-timing-function: ease;
	// animation-delay: 0ms;
	// animation-direction: normal;
// animation-direction: normal;
	// animation-fill-mode: reverse;
}
