// 基本設定 ======================================
input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea{
	@include font-size(16);
	box-sizing: border-box;
	width: 100%;
	max-width: 100%;
	padding: calc( ( 10 / 16 ) * 1em ) calc( ( 13 / 16 ) * 1em );
	transition: 300ms;
	letter-spacing: .1em;
	color: $text_default;
	border: 1px solid $brand_brown;
	border-radius: 4px;
	background-color: #fff;
	@media #{$sp}{
		font-size: 16px;
	}
	& + input[type='text'],
	& + input[type='email'],
	& + input[type='password'],
	& + input[type='tel'],
	& + input[type='text'],
	& + input[type='url'],
	& + textarea{
		margin-left: 12px;
	}
	&:focus{
		transition: 300ms;
		border: 1px solid $brand_brown;
		background-color: #fff;
		box-shadow: 0 0 8px rgba($brand_black, .2);
	}
}



input[type='radio'],
input[type='checkbox']{
	@include font-size(16);
	cursor: pointer;
}

textarea{
	line-height: 1.65;
	height: 15em;
}

select{
	padding: calc( ( 14 / 16 ) * 1em ) calc( ( 13 / 16 ) * 1em );
	// background-image: url('#{$imgPath}/icon/select.svg);
	background-repeat: no-repeat;
	background-position: center right 12px;
	background-size: 1em;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea{
	width: 100%;
	&.xsm{
		width: 20%;
	}
	&.sm{
		width: 30%;
	}
	&.md{
		width: 60%;
		@media #{$sp}{
			width: 100%;
		}
	}
	&.lg{
		width: 80%;
		@media #{$sp}{
			width: 100%;
		}
	}
	&.xlg{
		width: 100%;
	}
	&.zip-01{
		width: 4.5em;
	}
	&.zip-02{
		width: 5.5em;
	}
}

// フォームのボタン装飾 ======================================
.form-button{
	margin-top: 40px;
	text-align: center;
	input,
	button{
		@include font-size(16);
		min-width: 280px;
		padding: 1.5em 3em;
		text-align: center;
		letter-spacing: .1em;
		border: 1px solid transparent;
		border-radius: 0;
		-webkit-appearance: button;
		appearance: button;
		&.error_button{
			cursor: no-drop;
			color: #666;
			border: 1px solid transparent;
			background-color: #ccc;
		}
		&.confirm_button{
			cursor: pointer;
			color: #fff;
			border: 1px solid transparent;
			background-color: $brand_red;
		}
		&.back_button{
			cursor: pointer;
			color: $brand_black;
			border: 1px solid $brand_black;
			background-color: #fff;
		}
		&.submit_button{
			cursor: pointer;
			color: #fff;
			border: 1px solid $brand_red;
			background-color: $brand_red;
		}
	}
}

// 必須・任意のラベル ======================================
span.req{
	@include font-size(10);
	display: inline-block;
	margin-left: .6em;
	padding: 1px 8px 2px;
	vertical-align: 20%;
	letter-spacing: .1em;
	color: #fff;
	border: 1px solid $brand_red;
	border-radius: 2px;
	background-color: $brand_red;
}

span.opt{
	@include font-size(10);
	display: inline-block;
	margin-left: .6em;
	padding: 1px 8px 2px;
	vertical-align: 20%;
	letter-spacing: .1em;
	color: $brand_gray;
	border: 1px solid $brand_gray;
	border-radius: 2px;
	background-color: #fff;
}

// ラジオボタン変更 ======================================
.mwform-radio-field{
	@media #{$sp}{
		margin-top: 12px;
	}
	& + .mwform-radio-field{
		margin: 1.5em 0 0 0 !important;
	}
	&:last-child{
		margin-bottom: 0;
		& > label{
			margin-right: 0;
		}
	}
	& > label{
		@include font-size(16);
		margin-right: 20px;
		letter-spacing: .1em;
		@media #{$sp}{
			margin-right: 0;
		}
		input[type='radio']{
			display: none;
			&:checked{
				& + .mwform-radio-field-text{
					background: transparent url('#{$imgPath}/icon/checkbox-on.svg') center left no-repeat;
					background-size: contain;
					@media #{$sp}{
						// font-size: vsp(14);
						width: 100%;
						background-size: contain;
					}
				}
			}
		}
		.mwform-radio-field-text{
			padding-top: .2em;
			padding-bottom: .2em;
			padding-left: 1.8em;
			background: transparent url('#{$imgPath}/icon/checkbox-off.svg') center left no-repeat;
			background-size: contain;
			@media #{$sp}{
				// font-size: vsp(14);
				width: 100%;
				padding-left: 2em;
				background-size: contain;
			}
		}
	}
}


// チェックボックス変更 ======================================
.mwform-checkbox-field{
	&:last-child{
		& > label{
			margin-right: 0;
		}
	}
	& > label{
		@include font-size(16);
		margin-right: 20px;
		letter-spacing: .1em;
		@media #{$sp}{
		}
		input[type='checkbox']{
			display: none;
			&:checked{
				& + .mwform-checkbox-field-text{
					background: transparent url('#{$imgPath}/icon/checkbox-on.svg') center left no-repeat;
					background-size: contain;
					@media #{$sp}{
						// font-size: vsp(14);
						width: 100%;
						background-size: contain;
					}
				}
			}
		}
		.mwform-checkbox-field-text{
			padding-top: .2em;
			padding-bottom: .2em;
			padding-left: 1.8em;
			background: transparent url('#{$imgPath}/icon/checkbox-off.svg') center left no-repeat;
			background-size: contain;
			@media #{$sp}{
				// font-size: vsp(14);
				width: 100%;
				padding-left: 2em;
				background-size: contain;
			}
		}
	}
}

// ボタンの切り替え（入力画面・確認画面） ======================================
.form-button{
	&.input{
		div.mw_wp_form_confirm &,
		div.mw_wp_form_complete &{
			display: none;
		}
	}
	&.confirm{
		div.mw_wp_form_input &,
		div.mw_wp_form_complete &{
			display: none;
		}
	}
	&.complete{
		div.mw_wp_form_input &,
		div.mw_wp_form_confirm &{
			display: none;
		}
	}
}

.mw_wp_form{
	table{
		.vertical-item{
			& + .vertical-item{
			}
		}
	}
}

div.wpcf7{
	.ajax-loader{
		display: block;
		margin: 0 auto;
	}
	.form_content{
		.wpcf7-form-control-wrap.subject{
			@media #{$sp}{
				display: block;
				margin-top: 12px;
			}
		}
		span.wpcf7-list-item{
			margin: 0 0 1.5em 0;
			width: 100%;
			display: block;
			&:last-child{
				margin-bottom: 0;
			}
			& > label{
				@include font-size(16);
				margin-right: 20px;
				letter-spacing: .1em;
				@media #{$sp}{
					margin-right: 0;
				}
				input[type='checkbox']{
					display: none;
					&:checked{
						& + .wpcf7-list-item-label{
							padding-top: .2em;
							padding-bottom: .2em;
							background: transparent url('#{$imgPath}/icon/checkbox-on.svg') center left no-repeat;
							background-size: 20px 20px;
							@media #{$sp}{
								// font-size: vsp(14);
								width: 100%;
								background-size: contain;
							}
						}
					}
				}
				.wpcf7-list-item-label{
					padding-top: .2em;
					padding-bottom: .2em;
					padding-left: 1.8em;
					background: transparent url('#{$imgPath}/icon/checkbox-off.svg') center left no-repeat;
					background-size: 20px 20px;
					@media #{$sp}{
						// font-size: vsp(14);
						width: 100%;
						padding-left: 2em;
						background-size: contain;
					}
				}
			}
		}
	}
}
