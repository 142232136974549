// extend ===================================================*/
%clearfix{
	zoom: 1;
	&:after{
		display: block;
		clear: both;
		content: '';
	}
}

@keyframes fadeIn{
	0%{
		display: none;
		opacity: 0;
	}
	100%{
		display: block;
		opacity: 1;
	}
}

%gridFadein{
	animation: fadeIn 200ms forwards;
}

@keyframes fadeOut{
	0%{
		display: block;
		opacity: 1;
	}
	100%{
		display: none;
		opacity: 0;
	}
}

%gridFadeOut{
	animation: fadeOut 200ms forwards;
}

// gallery_col_4 ===================================================*/
%gallerycol4,
%gallery_col_4{
	@extend %clearfix;
	@media #{$sp}{
		@include flex;
		align-items: stretch;
		flex-wrap: wrap;
		width: 100% !important;
		height: auto !important;
	}
	& > a{
		margin: 0 5px 8px;
		&.op0{
			// opacity: .1;
			display: block;
			opacity: 0;
		}
		&.op1{
			display: block;
			opacity: 1;
		}
		&.grid-fadeIn{
			@extend %gridFadein;
			display: none;
		}
		&.grid-fadeOut{
			@extend %gridFadeOut;
			display: block;
		}
		@media #{$sp}{
			position: relative !important;
			top: auto !important;
			left: auto !important;
			width: 48%;
			margin: 0 0 vsp(20) 0;
			transition-delay: 0 !important;
			transition-duration: 0 !important;
			transition-property: opacity, transform;
			transform: translate3d(0px, 0px, 0px) !important;
			text-decoration: none;
		}
		article{
			position: relative;
			overflow: hidden;
			width: 240px;
			transform-origin: center center;
			border-radius: 2px;
			@media #{$sp}{
				width: 100%;
			}
			.eyecatch{
				img{
					width: 100%;
					transform-origin: center center;
					border-radius: 2px;
					@media #{$sp}{
						vertical-align: bottom;
					}
				}
			}
			&:hover{
				.eyecatch{
					img{
						transform: scale(1.1, 1.1);
						@media #{$sp}{
							transform: scale(1, 1);
						}
					}
				}
				.title{
					background-color: rgba(42,30,4,.7);
				}
			}
			.meta{
				position: absolute;
				top: 0;
				left: 0;
				padding: 8px;
				background-color: rgba(0,0,0,.8);
				span.number{
					@include font-maven-bold;
					@include font-size(16);
					font-weight: bold;
					display: inline-block;
					text-transform: uppercase;
					color: #fff;
				}
				span.category{
					@include font-tsukushi;
					@include font-size(12);
					display: inline-block;
					color: #fff;
				}
			}
			.title{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 8px;
				background-color: rgba(42,30,4,.5);
				@media #{$sp}{
					position: relative;
					bottom: auto;
					left: auto;
					padding: vsp(6);
					background-color: rgba(42,30,4,1);
				}
				h1{
					@include font-tsukushi-bold;
					@include font-size(14);
					line-height: 1.5;
					margin-bottom: 8px;
					letter-spacing: 2px;
					color: #fff;
					@media #{$sp}{
						@include font-size(14);
						@include text-overflow;
						line-height: 1.4;
						margin-bottom: 4px;
						letter-spacing: normal;
					}
				}
				time{
					@include font-maven-bold;
					@include font-size(12);
					font-weight: bold;
					display: inline-block;
					color: #fff;
					@media #{$sp}{
						@include font-size(10);
					}
				}
			}
		}
	}
}


// gallery_col_3 ===================================================*/
%gallery_col_3{
	@extend %clearfix;
	@media #{$sp}{
		@include flex;
		align-items: stretch;
		flex-wrap: wrap;
		width: 100% !important;
		height: auto !important;
	}
	& > a{
		display: block;
		margin: 0 1.5px 3px;
		@media #{$sp}{
			position: relative !important;
			top: auto !important;
			left: auto !important;
			width: 48%;
			margin: 0 0 vsp(20) 0;
			transition-delay: 0 !important;
			transition-duration: 0 !important;
			transition-property: opacity, transform;
			transform: translate3d(0px, 0px, 0px) !important;
			text-decoration: none;
		}
		article{
			position: relative;
			overflow: hidden;
			width: 240px;
			transform-origin: center center;
			border-radius: 2px;
			@media #{$sp}{
				width: 100%;
			}
			.eyecatch{
				img{
					width: 100%;
					transform-origin: center center;
					border-radius: 2px;
					@media #{$sp}{
						vertical-align: bottom;
					}
				}
			}
			&:hover{
				.eyecatch{
					img{
						transform: scale(1.1, 1.1);
						@media #{$sp}{
							transform: scale(1, 1);
						}
					}
				}
				.title{
					background-color: rgba(42,30,4,.7);
				}
			}
			.meta{
				position: absolute;
				top: 0;
				left: 0;
				padding: 8px;
				background-color: rgba(0,0,0,.8);
				span.number{
					@include font-maven-bold;
					@include font-size(16);
					font-weight: bold;
					display: inline-block;
					text-transform: uppercase;
					color: #fff;
				}
				span.category{
					@include font-tsukushi;
					@include font-size(12);
					display: inline-block;
					color: #fff;
				}
			}
			.title{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 8px;
				background-color: rgba(42,30,4,.5);
				@media #{$sp}{
					position: relative;
					bottom: auto;
					left: auto;
					padding: vsp(6);
					background-color: rgba(42,30,4,1);
				}
				h1{
					@include font-tsukushi-bold;
					@include font-size(14);
					line-height: 1.5;
					margin-bottom: 8px;
					letter-spacing: 2px;
					color: #fff;
					@media #{$sp}{
						@include font-size(14);
						@include text-overflow;
						line-height: 1.4;
						margin-bottom: 4px;
						letter-spacing: normal;
					}
				}
				time{
					@include font-maven-bold;
					@include font-size(12);
					font-weight: bold;
					display: inline-block;
					color: #fff;
					@media #{$sp}{
						@include font-size(10);
					}
				}
			}
		}
	}
}

%table_style{
	float: left;
	width: 49%;
	margin-right: 2%;
	letter-spacing: 3px;
	word-wrap: break-word;
	word-break: break-all;

	overflow-wrap: break-word;
	@media #{$sp}{
		width: 100%;
		& + table{
			background: transparent url('#{$imgPath}/company/line.png') center top repeat-x;
		}
	}
	&.fullwidth{
		width: 100%;
		margin-right: 0;
	}
	&:last-child{
		margin-right: 0;
	}
	tbody{
		tr{
			th{
				@include font-tsukushi-bold;
				@include font-size(14);
				width: 30%;
				padding: 17px 0 17px 16px;
				vertical-align: top;
				@media #{$sp}{
					min-width: 120px;
					padding: 17px 0 17px 0;
					letter-spacing: .1em;
				}
			}
			td{
				@include font-size(14);
				width: 70%;
				padding: 17px 40px 17px 0;
				vertical-align: top;
				@media #{$sp}{
					padding: 17px 0 17px 0;
					letter-spacing: .1em;
				}
				a{
					text-decoration: none;
					color: #333;
					&:hover{
						text-decoration: underline;
					}
				}
				&.line{
					height: 3px;
					padding: 0;
					background: transparent url('#{$imgPath}/company/line.png') center center repeat-x;
				}
				&.address{
					line-height: 1.53;
				}
				ul{
					li{
						@include font-size(13);
						line-height: 1.5;
						margin-bottom: 16px;
						padding-left: 16px;
						background: transparent url('#{$imgPath}/company/icon_list.png') left .35em no-repeat;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

// gallery_col_4 ===================================================*/
%post_content{
	h1,
	h2{
		display: none;
	}
	.h2_contact{
		display: block;
		h2{
			display: inline-block;
		}
	}
	h3{
		@include font-size(24);
		margin-bottom: 31px;
		padding: 13px 13px 14px 36px;
		letter-spacing: 3px;
		color: #fff;
		background: #333 url('#{$imgPath}/bg/bg_h3_post.png') left 14px center no-repeat;
		@media #{$sp}{
			@include font-size-sp(20);
			line-height: 1.5;
			padding: vsp(12) vsp(12) vsp(12) vsp(36);
			letter-spacing: .1em;
			background: #333 url('#{$imgPath}/bg/bg_h3_post.svg') left vsp(14) center no-repeat;
		}
	}
	h4{
		@include font-size(20);
		@include font-tsukushi-bold;
		margin-bottom: 31px;
		padding: 9px 13px 11px 36px;
		letter-spacing: 2px;
		color: #6c6458;
		border-top: 1px solid #6c6458;
		border-bottom: 2px solid #6c6458;
		background: transparent url('#{$imgPath}/bg/bg_h4_post.png') left 14px center no-repeat;
		@media #{$sp}{
			@include font-size-sp(18);
			line-height: 1.5;
			padding: vsp(8) vsp(8) vsp(8) vsp(36);
			letter-spacing: .1em;
			background: transparent url('#{$imgPath}/bg/bg_h4_post.svg') left vsp(14) center no-repeat;
		}
	}
	p{
		@include font-size(16);
		line-height: 1.88;
		margin-bottom: 31px;
		letter-spacing: 1px;
		color: #333;
		@media #{$sp}{
			@include font-size(14);
			margin-bottom: 30px;
			// text-align: justify;
			letter-spacing: .05em;
		}
		& + h4,
		& + h3{
			margin-top: 64px;
		}
	}
	strong,
	b{
		@include font-tsukushi-bold;
	}
	img{
		display: block;
		margin: 32px auto;
		@media #{$sp}{
			margin: vsp(45) auto;
		}
	}
	ul{
		width: 100%;
		margin-bottom: 31px;
		li{
			@include font-size(16);
			margin-bottom: 16px;
			padding-left: 30px;
			background: transparent url('#{$imgPath}/bg/bg_list_post.png') left 12px center no-repeat;
			@media #{$sp}{
				@include font-size(14);
				background: transparent url('#{$imgPath}/bg/bg_list_post.svg') left 12px center no-repeat;
			}
		}
	}
	.beforeafter-content{
		width: 100%;
		margin: 48px auto;
		.before-content{
			margin-bottom: 32px;
		}
		.after-content{
			margin-bottom: 20px;
		}
		.before-lable,
		.after-lable{
			@include font-maven;
			@include font-size(18);
			line-height: 1;
			position: relative;
			z-index: 2;
			margin-bottom: 12px;
			padding: 8px 12px;
			border-radius: 4px;
			background-color: #f3f2ef;
			&:after{
				position: absolute;
				z-index: 1;
				top: -5px;
				left: 16px;
				display: block;
				width: 6px;
				height: 10px;
				content: '';
				transform: rotate(-45deg);
				background-color: #f3f2ef;
			}
		}
		.before-photo,
		.after-photo{
			img{
				margin-top: 0;
				margin-bottom: 16px;
			}
		}
	}
}
