// section padding設定 ===================================================*/
@mixin section-padding(){
	padding: 56px 0;
}

// fontsize ===================================================*/
@mixin font-size($size: 13, $base: 16){
	font-size: $size + px;
}

@mixin font-size-sp($size: 13){
	font-size: vsp($size);
}

// antialiased ===================================================*/
@mixin font-smoothing($value: antialiased){
	@if $value == antialiased{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	@elseif $value == subpixel{
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

// link color ===================================================*/
@mixin default-link-color($link-color: $link-text-color, $hover-color: $hover-text-color){
	a{
		color: $link-color;
		&:hover,
		&:active,
		&:focus{
			color: $hover-color;
		}
	}
}

// text decoration ===================================================*/
@mixin text-decoration(){
	a{
		&:link,
		&:hover,
		&:active,
		&:focus{
			text-decoration: none;
		}
	}
}

// text overflow ===================================================*/
@mixin text-overflow(){
	// overflow: hidden;
	// white-space: nowrap;
	// text-overflow: ellipsis;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;

	-webkit-line-clamp: 2;
}

// background font ===================================================*/
@mixin background-font($color, $content, $top){
	position: relative;
	&:before{
		font-family: icomoon;
		font-size: inherit;
		font-weight: normal;
		font-style: normal;
		position: absolute;
		top: $top;
		left: 8px;
		padding-right: .5em;
		content: $content;
		text-decoration: inherit;
		color: $color;
	}
}

// animation of scale ===================================================*/
@mixin animation-scale(){
	transition: all .2s ease-in-out;
	transform-origin: center center;
	&:hover{
		transform: scale(1.1 1.1);
	}
}

// placeholder ===================================================*/
@mixin placeholder($color){
	input:-moz-placeholder,
	textarea:-moz-placeholder{
		color: $color;
	}
	input::-moz-placeholder,
	textarea::-moz-placeholder{
		color: $color;
	}
	input:-ms-input-placeholder,
	textarea:-ms-input-placeholder{
		color: $color;
	}
	input::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder{
		color: $color;
	}
}

// flex ===================================================*/
@mixin flex( $pc: null, $tb : null, $sp : null ){
	display: flex;
	align-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	justify-content: space-between;
	@if $pc != null{
		flex-direction: $pc;
	}
	@if $tb != null{
		@media #{$tb}{
			flex-direction: $tb;
		}
	}
	@if $sp != null{
		@media #{$sp}{
			flex-direction: $sp;
		}
	}
	& > *{
		min-height: percentage(0);
	}
}

@mixin letter-spacing($font-size, $space){
	$result : $space / $font-size;
	$result : $result * 1em;
	letter-spacing: $result;
}
