// aside ===================================================*/
aside{
	width: 100%;
	& > .wrap{
		width: 100%;
		& > .content{
			width: 1000px;
			margin: 0 auto;
		}
	}
}

aside.link{
	margin-top: 62px;
	padding: 35px 0;
	background-color: #eeece9;
	& > .wrap{
		& > .content{
			@extend %clearfix;
			padding: 0 63px;
			figure{
				float: left;
				width: 233px;
				margin-right: 87px;
				&:last-child{
					margin-right: 0;
				}
				a{
					img{
						margin-bottom: 13px;
						opacity: 1;
					}
					&:hover{
						img{
							opacity: .7;
						}
					}
				}
				figcaption{
					@include font-size(12);
					line-height: 1.67;
					padding: 0 8px;
					text-align: center;
				}
			}
		}
	}
}



// aside ===================================================*/
aside.secondary{
	section.category{
		margin-bottom: 20px;
		padding: 6px;
		background: transparent url('#{$imgPath}/bg/bg_aside.png') center center no-repeat;
		background-size: cover;
		@media #{$sp}{
			padding: 6px 6px 0 6px;
			background: transparent url('#{$imgPath}/bg/bg_aside@2x.jpg') center center no-repeat;
			background-size: cover;
		}
		& > .wrap{
			& > .content{
				ul{
					width: 100%;
					@media #{$sp}{
						@include flex;
						align-items: stretch;
						flex-wrap: wrap;
					}
					li{
						width: 100%;
						margin-bottom: 4px;
						background-color: #fff;
						@media #{$sp}{
							width: calc( ( 100% - 6px ) / 2 );
							margin-bottom: 6px;
						}
						&:last-child{
							margin-bottom: 0;
							@media #{$sp}{
								margin-bottom: 6px;
							}
						}
						a{
							@include font-size(14);
							display: block;
							padding: 12px 16px 12px 20px;
							transition: 300ms;
							text-decoration: none;
							color: $brand_black;
							background: #fff url('#{$imgPath}/aside/icon_post_arrow.png') right 6px center no-repeat;
							@media #{$sp}{
								@include font-size-sp(14);
								padding: vsp(16) vsp(16) vsp(16) vsp(10);
								transition: 0;
								letter-spacing: normal;
								background: #fff url('#{$imgPath}/aside/icon_post_arrow.svg') right 6px center no-repeat;
							}
							&:hover{
								transition: 300ms;
								text-decoration: none;
								color: #fff;
								background: $brand_black url('#{$imgPath}/aside/icon_post_arrow_hover.png') right 6px center no-repeat;
								@media #{$sp}{
									transition: 0;
									background: $brand_black url('#{$imgPath}/aside/icon_post_arrow_hover.svg') right 6px center no-repeat;
								}
							}
						}
					}
				}
			}
		}
	}
	section.recent{
		margin-bottom: 20px;
		padding: 6px;
		background: transparent url('#{$imgPath}/bg/bg_aside.png') center center no-repeat;
		background-size: cover;
		@media #{$sp}{
			// padding: 6px 6px 0 6px;
			// background: transparent url('#{$imgPath}/bg/bg_aside@2x.jpg') center center no-repeat;
			// background-size: cover;
			display: none;
		}
		& > .wrap{
			& > .content{
				@media #{$sp}{
					@include flex;
					flex-wrap: wrap;
				}
				& > a{
					display: block;
					margin-bottom: 4px;
					@media #{$sp}{
						width: calc( ( 100% - 6px ) / 2 );
						margin-bottom: 6px;
						text-decoration: none;
					}
					&:last-child{
						margin-bottom: 0;
						@media #{$sp}{
							margin-bottom: 6px;
						}
					}
					&:hover{
						& > article{
							transition: 300ms;
							background: $brand_black url('#{$imgPath}/aside/icon_post_arrow_hover.png') right 6px center no-repeat;
							.content{
								time{
									color: #fff;
								}
								p{
									color: #fff;
								}
							}
						}
					}
					& > article{
						@extend %clearfix;
						padding: 4px 16px 4px 4px;
						transition: 300ms;
						border-radius: 2px;
						background: #fff url('#{$imgPath}/aside/icon_post_arrow.png') right 6px center no-repeat;
						@media #{$sp}{
							@include flex;
							// padding: 0 vsp(16) 0 0;
							padding: 0;
							background-image: none;
						}
						.thumb{
							float: left;
							width: 66px;
							@media #{$sp}{
								float: none;
								width: 40%;
							}
							img{
								@media #{$sp}{
									vertical-align: bottom;
								}
							}
						}
						.content{
							float: right;
							width: 124px;
							padding: 4px 0;
							@media #{$sp}{
								float: none;
								width: 60%;
								padding: 0 vsp(12) 0 vsp(8);
								background: #fff url('#{$imgPath}/aside/icon_post_arrow.svg') right vsp(6) center no-repeat;
							}
							time{
								@include font-maven;
								@include font-size(12);
								font-weight: bold;
								display: inline-block;
								margin-bottom: 4px;
								color: #333;
								@media #{$sp}{
									@include font-size(10);
								}
							}
							p{
								@include font-size(12);
								line-height: 1.23;
								color: #111;
								@media #{$sp}{
									@include text-overflow;
								}
							}
						}
					}
				}
			}
		}
	}
	section.banner{
		margin-bottom: 20px;
		padding: 0;
		ul{
			li{
				margin-bottom: 12px;
				&:last-child{
					margin-bottom: 0;
				}
				a{
					img{
						opacity: 1;
					}
					&:hover{
						img{
							opacity: .7;
						}
					}
				}
			}
		}
	}
	section.orderflow_s{
		margin-bottom: 20px;
		padding: 0;
		& > .wrap{
			border: 1px solid #333;
			border-radius: 3px;
			background-color: #fff;
			& > .content{
				text-align: center;
				ul.pc{
					margin-bottom: 30px;
					@media #{$sp}{
						display: none;
					}
					li{
						@include flex;
						align-items: flex-start;
						flex-direction: column;
						padding: 12px 8px 30px 100px;
						text-align: left;
						background-repeat: no-repeat;
						&:nth-of-type(1){
							background-image: url('#{$imgPath}/bg/bg_orderflow_1.svg'), url('#{$imgPath}/bg/bg_aside_orderflow.svg');
							background-position: left 27px+20px top 12px, center bottom;
						}
						&:nth-of-type(2){
							background-image: url('#{$imgPath}/bg/bg_orderflow_2.svg'), url('#{$imgPath}/bg/bg_aside_orderflow.svg');
							background-position: left 27px+20px top 12px, center bottom;
						}
						&:nth-of-type(3){
							background-image: url('#{$imgPath}/bg/bg_orderflow_3.svg'), url('#{$imgPath}/bg/bg_aside_orderflow.svg');
							background-position: left 27px+20px top 12px, center bottom;
						}
						&:nth-of-type(4){
							padding: 12px 8px 50px 100px;
							background-image: url('#{$imgPath}/bg/bg_orderflow_4.svg'), url('#{$imgPath}/bg/bg_aside_orderflow_last.svg');
							background-position: left 27px+20px top 12px, center bottom;
						}
						p.step{
							// @include font-maven;
							// @include font-size(16);
							// margin-bottom: 4px;
							// padding-top: 4px;
							// text-transform: uppercase;
							@include font-size(12);
							@include letter-spacing(12, .75);
							@include font-maven;
							font-weight: 400;
							margin-bottom: 6px;
							padding: 2px 12px;
							text-transform: uppercase;
							color: #fff;
							border-radius: 20px;
							background-color: #b51820;
						}
						p.explanation{
							@include font-size(16);
							@include font-tsukushi-bold;
						}
					}
				}
				ul.sp{
					display: none;
					@media #{$sp}{
						display: block;
					}
					li{
						@include flex;
						align-items: center;
						flex-direction: row;
						justify-content: center;
						width: 100%;
						padding: 12px 8px 30px 8px;
						background-image: url('#{$imgPath}/bg/bg_aside_orderflow@2x.svg');
						background-repeat: no-repeat;
						background-position: center bottom;
						background-size: 100% auto;
						&:last-child{
							margin-bottom: 20px;
							padding: 12px 8px 40px 8px;
							background-image: url('#{$imgPath}/bg/bg_aside_orderflow_last@2x.svg');
						}
						.icon{
							width: 40px;
							margin-right: 12px;
						}
						.body{
							@include flex;
							align-items: flex-start;
							flex-direction: column;
							min-width: 100px;
							p.step{
								@include font-size(12);
								@include letter-spacing(12, .75);
								@include font-maven;
								font-weight: 400;
								margin-bottom: 6px;
								padding: 2px 12px;
								text-transform: uppercase;
								color: #fff;
								border-radius: 20px;
								background-color: #b51820;
							}
							p.explanation{
								@include font-size(16);
								@include font-tsukushi-bold;
							}
						}
					}
				}
				& > .contact{
					padding: 0 13px 13px 13px;
					& > p{
						&.logo{
							margin-bottom: 12px;
						}
						&.lead{
							@include font-size(13);
							@include font-tsukushi-bold;
							line-height: 1.38;
							margin-bottom: 15px;
							&.lead-small{
								display: block;
								margin: 8px 0;
								text-align: center;
							}
						}

						&.tel{
							a{
								@include flex;
								@include font-maven;
								@include font-size(16);
								@include letter-spacing(16, .5);
								font-weight: 400;
								align-items: center;
								justify-content: center;
								width: 100%;
								min-height: 32px;
								transition: 300ms;
								text-decoration: none;
								color: #fff;
								border-radius: 4px;
								background-color: #b5181f;
								@media #{$sp}{
									justify-content: center;
									width: 100%;
									margin-bottom: vsp(8);
								}
								.i{
									margin-right: 8px;
								}
								&:hover{
									transition: 300ms;
									background-color: lighten(#b5181f, 10%);
								}
							}
						}

						&.form{
							a{
								@include flex;
								@include font-tsukushi-bold;
								@include font-size(14);
								@include letter-spacing(14, .5);
								font-weight: 400;
								align-items: center;
								justify-content: center;
								width: 100%;
								min-height: 32px;
								transition: 300ms;
								text-decoration: none;
								color: #fff;
								border-radius: 4px;
								background-color: #333;
								@media #{$sp}{
									justify-content: center;
									width: 100%;
								}
								.i{
									margin-right: 8px;
								}
								&:hover{
									transition: 300ms;
									background-color: lighten(#333, 10%);
								}
							}
						}


						// &.tel{
						// 	@include font-maven;
						// 	@include font-size(24);
						// 	font-weight: bold;
						// 	padding-left: 24px;
						// 	letter-spacing: 1px;
						// 	color: #f14146;
						// 	background: transparent url('#{$imgPath}/aside/icon_tel.png') 3px center no-repeat;
						// 	html.safari &{
						// 		letter-spacing: 0;
						// 	}
						// 	&.sp{
						// 		@include font-size(23);
						// 		letter-spacing: 0;
						// 	}
						// }
					}
					span{
					}
					.button{
						a{
							@include font-tsukushi-bold;
							@include font-size(15);
							display: block;
							width: 100%;
							padding: 8px 0;
							text-decoration: none;
							color: #f14146;
							border: 1px solid #f14146;
							border-radius: 1px;
							&:hover{
								color: #fff;
								background-color: #f14146;
							}
						}
					}
				}
			}
		}
	}
}
