// スマホの比率の計算式
$default-device-width-sp: 414px;

@function remove-unit($number){
	@if unitless($number){
		@warn "Unit is not attached.";
		@return $number;
	}
	$unit: unit($number);
	$compare-list: '%', 'px', 'em', 'rem', 'vw', 'vh', 'vmin', 'vmax', 'ch', 'ex', 'cm', 'mm', 'q', 'in', 'pt', 'pc', 'mozmm', 'dpi', 'dpcm', 'dppx', 'deg', 'grad', 'rad', 'turn', 's', 'ms', 'Hz', 'kHz';
	$compare-item: 1%, 1px, 1em, 1rem, 1vw, 1vh, 1vmin, 1vmax, 1ch, 1ex, 1cm, 1mm, 1q, 1in, 1pt, 1pc, 1mozmm, 1dpi, 1dpcm, 1dppx, 1deg, 1grad, 1rad, 1turn, 1s, 1ms, 1Hz, 1kHz;
	$compare-length: length($compare-list);
	@for $i from 1 through $compare-length{
		@if $unit == nth($compare-list, $i){
			$value: $number / nth($compare-item, $i);
			@return $value;
		}
	}
}

@function vsp($size, $viewport: $default-device-width-sp){
	$viewport: remove-unit($default-device-width-sp);
	$rate: 100 / $viewport;
	@return $rate * $size * 1.05vw;
}