// header ===================================================*/
header{
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0,0,.05);
	background-color: #fff;

	will-change: transform;
	& > .pc{
		&.wrap{
			width: 100%;
			& > .content{
				@extend %clearfix;
				width: 1000px;
				margin: 0 auto;
			}
		}
		&.description{
			@include font-hannari;
			@include font-size(10);
			height: auto;
			padding: 8px 0;
			transition: 200ms;
			transform: scaleY(1) translateY(0);
			transform-origin: center top;
			opacity: 1;
			border-bottom: 1px solid rgba(0,0,0,.05);
			background: transparent url('#{$imgPath}/bg/bg_description.png') center center no-repeat;
			background-size: cover;
		}
	}
	& > .sp{
		&.wrap{
			padding: vsp(10) vsp(16);
			.content{
				@include flex;
			}
		}
		.logo-w{
		}
		.logo-i{
			width: 137px;
			img{
				width: 137px;
				height: 32px;
			}
		}
		.snav-w{
		}
	}
	&.animation{
		will-change: transform;
		& > .pc{
			&.wrap{
				& > .content{
				}
			}
			&.description{
				height: 0;
				padding: 0;
				transition: 200ms;
				transform: scaleY(0) translateY(-100%);
				transform-origin: center top;
				opacity: 0;
			}
		}
	}
}


header{
	& > .pc{
		display: block;
		@media #{$sp}{
			display: none;
		}
	}
	& > .sp{
		display: none;
		@media #{$sp}{
			display: block;
		}
	}
}
