// Mediaquery ===================================================*/
// @media #{$lg} {
// 	font-size: 18px;
// }
// として使う

$ds: ' (min-width: 0px) and (max-width: 1000px)';
$tl: ' (min-width: 0px) and (max-width: 860px)';
$tm: ' (min-width: 0px) and (max-width: 768px)';
$ts: ' (min-width: 0px) and (max-width: 640px)';
$sl: ' (min-width: 0px) and (max-width: 480px)';
$sm: ' (min-width: 0px) and (max-width: 380px)';
$ss: ' (min-width: 0px) and (max-width: 360px)';
$sx: ' (min-width: 0px) and (max-width: 320px)';

$sp: 'only screen and (min-width: 0px) and (max-width: 480px), only screen and (min-width: 0px) and (max-width: 480px) and (min-resolution: 2dppx), only screen and (min-width: 0px) and (max-width: 480px) and (-min-device-pixel-ratio:2)';